import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { Header, NavMenu, ModalResetPass, Modal } from "components/Elements";

import { useRoutes } from "./routes";
import { useStore, useTranslate, useHttp } from "./hooks";
import { ContextStore } from "./context/ContextStore";

import { navConfig } from "./config/configs";

import { removeQueryParam } from "utils/common";

import "./App.css";
import classes from "App.module.scss";

function App() {
  const wrapPageRef = useRef(null);

  const {
    // loading,
    request,
    error,
    clearError,
  } = useHttp();

  const {
    token,
    login,
    logout,
    userId,
    ready,
    isAuthenticated,
    lng,
    changeLanguage,
    notif,
    pageScrollDown,
    trigerSocket,
    setTrigerSocket,
    isOpenModalTutorial,
    setIsOpenModalTutorial,
  } = useStore(wrapPageRef);

  const routes = useRoutes(isAuthenticated);

  const [isOpenModalResetPass, setIsOpenModalResetPass] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslate();

  useEffect(() => {
    if (error) {
      console.log(error);
    }
    clearError();
    // eslint-disable-next-line
  }, [error, clearError]);

  useEffect(() => {
    if (searchParams.get("resetPass")) {
      setIsOpenModalResetPass(true);
    }
    // eslint - disable - next - line;
  }, [searchParams]);

  const closeModalResetPass = () => {
    setIsOpenModalResetPass(false);
  };

  const closeModalTutorial = () => {
    setIsOpenModalTutorial(false);
  };

  const removeParamResetPass = () => {
    removeQueryParam("resetPass", searchParams, setSearchParams);
  };

  const resetPass = async (password) => {
    try {
      const { message } = await request("/api/auth/resetPassword", "POST", {
        newPass: password,
        resetKey: searchParams.get("resetPass"),
      });
      notif(message, "alert");
    } catch (e) {
      notif(e.message, "error");
    }
  };

  if (!ready) {
    return <p>...loading...</p>;
  }

  return (
    <ContextStore.Provider
      value={{
        token,
        userId,
        login,
        logout,
        isAuthenticated,
        lng,
        changeLanguage,
        notif,
        pageScrollDown,
        trigerSocket,
        setTrigerSocket,
        setIsOpenModalTutorial,
      }}
    >
      <div className={classes.container}>
        {isAuthenticated && (
          <Header changeLanguage={changeLanguage} lng={lng} />
        )}
        <div className={classes.wrapPage} ref={wrapPageRef}>
          {routes}
        </div>
        {isAuthenticated && <NavMenu navConfig={navConfig} />}
      </div>

      {/* !!!! block of the starting modal: "false" */}
      {false && isAuthenticated && isOpenModalTutorial && (
        <Modal
          onSubmit={() => {}}
          open={isOpenModalTutorial}
          closeModal={closeModalTutorial}
          title="tutorial"
        />
      )}

      {isOpenModalResetPass && (
        <ModalResetPass
          onSubmit={resetPass}
          open={isOpenModalResetPass}
          closeModal={closeModalResetPass}
          title={t("RESET_PASS_TITLE")}
          textButtonOk={t("RESET_PASWORD")}
          additionalFuncCancel={removeParamResetPass}
        />
      )}
    </ContextStore.Provider>
  );
}

export default App;
