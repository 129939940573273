export const AllText = {
  NONE404: { eng: "nullText", ua: "nullText", rus: "nullText" },
  EVENT_MANAGER: {
    eng: "TIME",
    ua: "TIME",
    rus: "TIME",
  },
  LOGIN: { eng: "Login", ua: "Логін", rus: "Вход" },
  REGISTER: { eng: "Register", ua: "Реєстрація", rus: "Регистрация" },
  EMAIL: { eng: "Email", ua: "Електронна пошта", rus: "Email" },
  PASSWORD: { eng: "Password", ua: "Пароль", rus: "Пароль" },
  FULL_NAME: { eng: "Full Name", ua: "Повне ім'я", rus: "Полное имя" },
  CONFIRM_PASSWORD: {
    eng: "Confirm Password",
    ua: "Підтвердити пароль",
    rus: "Подтвердите пароль",
  },
  CHANGE_LANGUAGE: {
    eng: "Change language",
    ua: "Змінити мову",
    rus: "Изменить язык",
  },

  EVENTS: { eng: "Events", ua: "", rus: "" },
  KEYS: { eng: "Keys", ua: "Ключі", rus: "Ключи" },
  MENU: { eng: "Menu", ua: "Меню", rus: "Меню" },

  MESSAGE: { eng: "Message", ua: "Повідомлення", rus: "Сообщение" },
  EVENT_DATE: { eng: "Event date", ua: "Дата події", rus: "Дата события" },
  NAME: { eng: "Name", ua: "Ім'я", rus: "Имя" },
  STATUS: { eng: "Status", ua: "Статус", rus: "Статус" },
  CANCEL: { eng: "Cancel", ua: "Скасувати", rus: "Отмена" },
  OK: { eng: "Ok", ua: "Ok", rus: "Ok" },
  SAVE: { eng: "Save", ua: "Зберегти", rus: "Сохранить" },
  DELETE: { eng: "Delete", ua: "Видалити", rus: "Удалить" },

  LOGOUT: { eng: "Logout", ua: "Вихід", rus: "Выход" },

  CREATE_EVENT: {
    eng: "Create event",
    ua: "Створити подію",
    rus: "Создать событие",
  },
  CREATE: { eng: "Create", ua: "Створити", rus: "Создать" },
  CREATE_KEY: { eng: "Create key", ua: "Створити ключ", rus: "Создать ключ" },
  EXPIRED_DATE: {
    eng: "Expired Date",
    ua: "Дата закінчення терміну дії",
    rus: "Дата окончания срока действия",
  },
  EDIT_KEY: {
    eng: "Edit key",
    ua: "Редагувати ключ",
    rus: "Редактировать ключ",
  },

  ERROR_NAME_REQ: {
    eng: "Name is required",
    ua: "Ім'я є обов'язковим",
    rus: "Имя является обязательным",
  },
  ERROR_EVENT_DATE_REQ: {
    eng: "Event Date is required",
    ua: "Дата події є обов'язковою",
    rus: "Дата события обязательна",
  },
  ERROR_EXPIRED_DATE_REQ: {
    eng: "Expired Date is required",
    ua: "Дата закінчення терміну дії є обов'язковою",
    rus: "Дата окончания срока действия обязательна",
  },

  USAGE_COUNT: {
    eng: "Usage Count",
    ua: "Лічильник використання",
    rus: "Счетчик использования",
  },
  UNLIMITED: { eng: "Unlimited", ua: "Необмежено", rus: "Неограниченный" },

  STOP: { eng: "Stop", ua: "Стоп", rus: "Стоп" },
  STOP_EVENT: {
    eng: "Stop Event",
    ua: "Зупинити подію",
    rus: "Остановить событие",
  },
  WANT_STOP_EVENT: {
    eng: "Do you really want to stop the event?",
    ua: "Ви дійсно хочете зупинити подію?",
    rus: "Вы действительно хотите остановить событие?",
  },
  DELETE_EVENT: {
    eng: "Delete Event",
    ua: "Видалити подію",
    rus: "Удалить событие",
  },
  WANT_DELETE_EVENT: {
    eng: "Do you really want to delete the event?",
    ua: "Ви дійсно хочете видалити подію?",
    rus: "Вы действительно хотите удалить событие?",
  },

  DELETE_KEY: { eng: "Delete Key", ua: "Видалити ключ", rus: "Удалить ключ" },
  WANT_DELETE_KEY: {
    eng: "Do you really want to delete the key?",
    ua: "Ви дійсно хочете видалити ключ?",
    rus: "Вы действительно хотите удалить ключ?",
  },
  SHARE: { eng: "Share", ua: "Поділитися", rus: "Поделиться" },

  USE_KEY: {
    eng: "Use Key",
    ua: "Використовувати ключ",
    rus: "Использовать ключ",
  },
  CREATED_DATE: {
    eng: "Created date",
    ua: "Дата створення",
    rus: "Дата создания",
  },
  DAY: { eng: "day", ua: "день", rus: "день" },
  DAYS: { eng: "days", ua: "днів", rus: "дней" },
  TODAY: { eng: "Today", ua: "Сьогодні", rus: "Сегодня" },

  CANCELED: { eng: "Canceled", ua: "Скасовано", rus: "Отменено" },
  PENDING: { eng: "Pending", ua: "Очікується", rus: "Ожидается" },
  SUCCESS: { eng: "Success", ua: "Успішно", rus: "Успешно" },
  EXPIRED: { eng: "Expired", ua: "Закінчився", rus: "Истек" },
  DELETED: { eng: "Deleted", ua: "Видалено", rus: "Удалено" },
  UNKNOWN: { eng: "Unknown", ua: "Невідомо", rus: "Неизвестно" },

  CLOSE: { eng: "Close", ua: "Закрити", rus: "Закрыть" },

  INV_EVENT: {
    eng: "Invitation to the event",
    ua: "Запрошення на подію",
    rus: "Приглашение на событие",
  },
  ERROR_USELINK: {
    eng: "To use the link, log in to the app or register quickly",
    ua: "Для використання лінку увійдіть в додаток або пройдіть швидку реєстрацію",
    rus: "Для использования ссылки войдите в приложение или пройдите быструю регистрацию",
  },
  ERROR_USEKEY: {
    eng: "To use the key, log in to the app or register quickly",
    ua: "Для використання ключа увійдіть в додаток або пройдіть швидку реєстрацію",
    rus: "Для использования ключа войдите в приложение или пройдите быструю регистрацию",
  },

  NAME_KEY: { eng: "Name key", ua: "Назва ключа", rus: "Название ключа" },
  CREATE_EVENT_FOR: {
    eng: "Create event for",
    ua: "Створити подію для",
    rus: "Создать событие для",
  },

  KEY_LOADING: {
    eng: "Key loading…",
    ua: "Завантаження ключа…",
    rus: "Загрузка ключа…",
  },
  INVITATION: { eng: "Invitation", ua: "Запрошення", rus: "Приглашение" },
  ACCEPT_INVITATION: {
    eng: "Accept the invitation?",
    ua: "Прийняти запрошення?",
    rus: "Принять приглашение?",
  },
  ACCEPT: { eng: "Accept", ua: "Прийняти", rus: "Принять" },
  ADD_KEY_TITLE: { eng: "Add Key", ua: "Додати ключ", rus: "Добавить ключ" },
  WANT_ADD_KEY: {
    eng: "Do you want to add a key?",
    ua: "Ви хочете додати ключ?",
    rus: "Хотите добавить ключ?",
  },
  ADD_KEY: { eng: "Add Key", ua: "Додати ключ", rus: "Добавить ключ" },

  ALL_KEYS: { eng: "All keys", ua: "Усі ключі", rus: "Все ключи" },
  MY_KEY: { eng: "My keys", ua: "Мої ключі", rus: "Мои ключи" },
  FRIENDS_KEYS: { eng: "Fiends keys", ua: "Ключі друзів", rus: "Ключи друзей" },

  CONFIRM_MAIL: { eng: "Confirm mail", ua: "", rus: "" },
  CONG_EMAIL_CONFIRMED: {
    eng: "Congratulations !!! Email confirmed.",
    ua: "",
    rus: "",
  },
  CONFIRM_LINK_ERROR: {
    eng: "Confirm Link error",
    ua: "",
    rus: "",
  },
  CONTINUE: {
    eng: "Continue",
    ua: "",
    rus: "",
  },

  FORGOT_PASS: {
    eng: "Forgot a password?",
    ua: "",
    rus: "",
  },
  ENTER_MAIL: {
    eng: "Enter the email which you registered your account, which you want to restore is now",
    ua: "",
    rus: "",
  },
  SEND_LINK: {
    eng: "Send link",
    ua: "",
    rus: "",
  },
  RESET_PASS_TITLE: {
    eng: "Reset Password",
    ua: "",
    rus: "",
  },
  RESET_PASWORD: {
    eng: "Reset Password",
    ua: "",
    rus: "",
  },

  PARTICIPANS: {
    eng: "Participants",
    ua: "",
    rus: "",
  },

  ONLY_PENDING: {
    eng: "Only pending",
    ua: "",
    rus: "",
  },

  CODE_1: {
    eng: "Invalid login or password",
    ua: "",
    rus: "",
  },

  CLICK_COPY_LINK: {
    eng: "Click to copy link",
    ua: "",
    rus: "",
  },

  LINK_COPY: {
    eng: "Link copy",
    ua: "",
    rus: "",
  },

  HOT_KEY_COPY: {
    eng: "Hot key copy",
    ua: "",
    rus: "",
  },
  CREATE_HOT_CODE: {
    eng: "Create hot code",
    ua: "",
    rus: "",
  },

  QR_SCANNING: {
    eng: "QR code scanning",
    ua: "Сканування QR-коду",
    rus: "",
  },

  INSERT_HOT_KEY: {
    eng: "Insert hot key",
    ua: "",
    rus: "",
  },
  USE_HOT_KEY: {
    eng: "Use hot key",
    ua: "",
    rus: "",
  },
  ERROR_KEY_REQ: {
    eng: "Key is required",
    ua: "",
    rus: "",
  },

  FOLLOW_LINK: {
    eng: "Follow the link",
    ua: "",
    rus: "",
  },
  LOADING: {
    eng: "Loading…",
    ua: "",
    rus: "",
  },
  EMAIL_CONFIRMED: {
    eng: "Email confirmed",
    ua: "",
    rus: "",
  },
  ADD_EVENT_YOURSELF: {
    eng: "Add an event for yourself",
    ua: "",
    rus: "",
  },

  INCORRECT_QR: {
    eng: "Incorrect QR code",
    ua: "",
    rus: "",
  },
  ORGANIZER: { eng: "Organizer", ua: "", rus: "" },
  CLEAR_ALL: { eng: "Clear All", ua: "", rus: "" },
  NOTIFICATIONS: { eng: "Notifications", ua: "", rus: "" },

  EVENT_ORGANIZE: { eng: "I am the event organize", ua: "", rus: "" },

  ADD_ADDRESS: { eng: "Add address", ua: "", rus: "" },
  ADDRESS: { eng: "Address", ua: "", rus: "" },
  ERROR_ADDRESS: {
    eng: "The address was not found on the map but you can save the entered text",
    ua: "",
    rus: "",
  },
  ERROR_FILL_FIELDS: { eng: "Please fill in all fields", ua: "", rus: "" },
  ERROR_REGISTER_PASS: {
    eng: "The password should be between 8 and 16 characters long and contain only Latin letters, numbers, or special characters (without quotation marks)",
    ua: "",
    rus: "",
  },
  ERROR_NOTMATCH_PASS: { eng: "Passwords do not match", ua: "", rus: "" },
  SUPPORT: { eng: "Support", ua: "", rus: "" },
  USE_FORM_SUPPORT: {
    eng: "Use the form to reach out to our support team with any questions or issues",
    ua: "",
    rus: "",
  },
  EMAIL_SUPPORT: { eng: "Email", ua: "", rus: "" },
  SUPPORT_TITLE: { eng: "Support", ua: "", rus: "" },
  DESCRIBE_PROBLEM: {
    eng: "Please, describe your problem",
    ua: "",
    rus: "",
  },
  MESSAGE_SUPPORT: { eng: "Your message to support", ua: "", rus: "" },
  SEND: {
    eng: "Send",
    ua: "",
    rus: "",
  },
  ERROR_EMAIL_REQ: {
    eng: "Email is required",
    ua: "",
    rus: "",
  },
  ERROR_MESSAGE_REQ: {
    eng: "Message is required",
    ua: "",
    rus: "",
  },
  ADDRESS_NOT_FOUND: {
    eng: "Address not found on the map (check with the event creator)",
    ua: "",
    rus: "",
  },

  ERROR_001: { eng: "User already exists", ua: "", rus: "" }, //реєстрація
  ERROR_002: {
    eng: "An incorrectly entered password or login", //логінізація
    ua: "",
    rus: "",
  },
};

export const parsLanguage = (text = "NONE404", lng = "eng") => {
  return AllText?.[text]?.[lng] ? AllText?.[text]?.[lng] : AllText.NONE404[lng];
};
