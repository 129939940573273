import { useSnackbar } from "notistack";

import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useTranslate } from "hooks";

export const useNotif = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useTranslate();

  const action = (snackbarId) => (
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
    >
      <CloseIcon sx={{ color: "var(--white)" }} />
    </IconButton>
  );

  const notif = (message, variant) => {
    enqueueSnackbar(t(message), {
      variant:
        (variant === "error" && "warning") ||
        (variant === "alert" && "success"),
      action,
      style: {
        backgroundColor:
          (variant === "error" && "var(--error-notif)") ||
          (variant === "alert" && "var(--alert-notif)"),
      },
    });
  };

  return { notif };
};
