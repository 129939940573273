import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { BottomNavigation, BottomNavigationAction } from "@mui/material";

import { IconMuiDraw } from "components/Elements";

import { useTranslate } from "hooks/useTranslate.hook";

export default function NavMenu({ navConfig }) {
  const [value, setValue] = useState();

  const { t } = useTranslate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleChange}
        sx={{
          backgroundColor: "var(--black)",
        }}
      >
        {navConfig.map(({ link, text, icon, counter }) => (
          <BottomNavigationAction
            key={link}
            label={t(`${text}`)}
            value={link}
            icon={<IconMuiDraw name={icon} />}
            LinkComponent={NavLink}
            to={link}
            sx={{
              color: "var(--white)",
              "&.Mui-selected": {
                color: "var(--icon-accent)",
              },
              "&.active": {
                color: "var(--icon-accent)",
              },
            }}
          />
        ))}
      </BottomNavigation>
    </>
  );
}
