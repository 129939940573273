import { useState, useCallback, useContext } from "react";
import { ContextStore } from "context/ContextStore";

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { logout } = useContext(ContextStore);
  const request = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      setLoading(true);
      try {
        if (body) {
          body = JSON.stringify(body);
          headers["Content-Type"] = "application/json";
        }
        const respons = await fetch(
          `${process.env.REACT_APP_BACKEND_URL || ""}${url}`,
          { method, body, headers }
        );
        const data = await respons.json();
        if (!respons.ok) {
          throw new Error(data.message || "Error");
        }
        setLoading(false);
        return data;
      } catch (e) {
        setLoading(false);
        setError(e.message);
        if (e.message === "Not autorization") {
          logout();
        }
        throw e;
      }
    },
    // eslint-disable-next-line
    []
  );

  const clearError = useCallback(
    () => setError(null), // eslint-disable-next-line
    []
  );

  return { loading, request, error, clearError };
};
