import React, { useState, useContext } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { PasswordField } from "components/Elements";

import { useTranslate } from "hooks/useTranslate.hook";

import { ContextStore } from "context/ContextStore";

import { passwordRegex } from "utils/common";

export default function ModalResetPass({
  onSubmit,
  open,
  closeModal,
  title = "",
  text = "",
  textButtonOk,
  textButtonCancel,
  additionalFuncCancel,
}) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { t } = useTranslate();

  const { notif } = useContext(ContextStore);

  const handleCancel = () => {
    additionalFuncCancel();
    closeModal();
  };

  const handleOk = () => {
    if (!password || !confirmPassword) {
      notif("ERROR_FILL_FIELDS", "error");
      return;
    }

    if (!passwordRegex.test(password)) {
      notif("ERROR_REGISTER_PASS", "error");
      return;
    }

    if (password !== confirmPassword) {
      notif("ERROR_NOTMATCH_PASS", "error");
      return;
    }

    onSubmit(password);
    additionalFuncCancel();
    closeModal();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{`${title}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          <PasswordField
            label={t("PASSWORD")}
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <TextField
            fullWidth
            id="standard-basic"
            label={t("PASSWORD")}
            variant="standard"
            type="password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            sx={{
              "& label.Mui-focused": {
                color: "var(--label-focused)",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "var(--underline-focused)",
              },
            }}
          /> */}
          <PasswordField
            label={t("CONFIRM_PASSWORD")}
            name="confirmPassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
          />
          {/* <TextField
            fullWidth
            id="standard-basic"
            label={t("CONFIRM_PASSWORD")}
            variant="standard"
            type="password"
            name="confirmPassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            sx={{
              "& label.Mui-focused": {
                color: "var(--label-focused)",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "var(--underline-focused)",
              },
            }}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            sx={{
              color: "var(--button-modal)",
            }}
          >
            {textButtonCancel || t("CANCEL")}
          </Button>
          <Button
            onClick={handleOk}
            autoFocus
            sx={{
              color: "var(--button-modal)",
            }}
          >
            {textButtonOk || t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
