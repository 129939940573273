import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Countdown = ({ duration, keyCountdown }) => {
  return (
    <CountdownCircleTimer
      isPlaying
      duration={duration}
      colors={["#4caf50", "#4caf50", "#ff6600", "#f91f2b0", "#f91f2b0"]}
      colorsTime={[duration, (duration * 3) / 4, duration / 2, duration / 5, 0]}
      size={140}
      strokeWidth={24}
      key={keyCountdown}
    >
      {({ remainingTime }) => (
        <span style={{ color: "var(--accent-orange)", fontSize: "30px" }}>
          {remainingTime}
        </span>
      )}
    </CountdownCircleTimer>
  );
};

export default Countdown;
