import { List, ListItem, ListItemText, ListItemAvatar } from "@mui/material";

import { AvatarImg } from "components/Elements";

const BodyModalParticipants = ({ participantsInfo }) => {
  const { participants } = participantsInfo;

  return (
    <List>
      {participants.map((item, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <AvatarImg fullName={item} foto="" />
          </ListItemAvatar>
          <ListItemText>{item}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default BodyModalParticipants;
