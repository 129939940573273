import { useState } from "react";

import { isMobile } from "react-device-detect";

import { Button, Chip, IconButton, Stack } from "@mui/material";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import InfoIcon from "@mui/icons-material/Info";
import DirectionsIcon from "@mui/icons-material/Directions";

import cn from "classnames";

import { Modal, ModalLink, MapAddressShow } from "components/Elements";

import {
  parsStatus,
  dateDDMMYYYY,
  dateHHmm,
  diffInDays,
  diffInMinutes,
} from "utils/common";

import { useTranslate } from "hooks/useTranslate.hook";

import classes from "./TableRow.module.scss";

const TableRow = ({
  eventDate,
  name,
  message,
  status,
  _id,
  handleStop,
  handleDel,
  createDate,
  eventlink,
  owner,
  userId,
  getParticipants,
  hotCode,
  setHotCode,
  generateHotKey,
  timeHotCode,
  timerId,
  tableScroll,
  isExpandedProp,
  address,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedProp ?? false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isOpenModalStop, setIsOpenModalStop] = useState(false);
  const [isOpenModalLink, setIsOpenModalLink] = useState(false);

  const { t } = useTranslate();
  // const linkInvitation = `${process.env.REACT_APP_URL}/useLinkevent/${eventlink}`;
  const linkInvitation = `/events?useKey=${eventlink}`;

  const handleToggleExpand = () => {
    if (isExpandedProp) {
      return;
    }

    setIsExpanded(!isExpanded);
    if (tableScroll && !isExpanded) {
      tableScroll();
    }
  };

  const closeModalLink = () => {
    hotCode && setHotCode(null);
    timerId && clearTimeout(timerId);
    setIsOpenModalLink(false);
  };

  const closeModalDelete = () => {
    setIsOpenModalDelete(false);
  };

  const closeModalStop = () => {
    setIsOpenModalStop(false);
  };

  const daysDiff = diffInDays(eventDate);
  const labelChipParse = () => {
    if (daysDiff > 1) {
      return `${daysDiff}  ${t("DAYS")}`;
    }
    if (daysDiff === 1) {
      return `1  ${t("DAY")}`;
    }
    if (daysDiff === 0 && diffInMinutes(eventDate) > 2) {
      return ` ${t("TODAY")} - ${dateHHmm(eventDate)}`;
    }
  };
  const labelChip = labelChipParse();

  const syntheticStatus = !labelChip ? 3 : status;

  const isChip = labelChip && status !== 3 && status !== 1;
  const isDisabledShare =
    !labelChip || status === 1 || status === 3 || status === 4;

  const isDisabledStop =
    !labelChip ||
    status === 1 ||
    status === 3 ||
    status === 4 ||
    userId !== owner;

  const isDisabledInfo = !labelChip || status === 3;

  const handleOpenAddressMobile = (addressValue) => {
    if (isMobile) {
      const url = `geo:0,0?q=${encodeURIComponent(addressValue)}`;
      window.location.href = url;
    }
  };

  return (
    <>
      <div
        className={cn(
          classes.row,
          isExpanded && classes.expanded,
          isExpandedProp && classes.rowToModal
        )}
      >
        <div className={classes.cell} onClick={handleToggleExpand}>
          {dateDDMMYYYY(eventDate)}
          <p className={cn(classes.timeCell, classes.secondaryTextCell)}>
            {dateHHmm(eventDate)}
          </p>
        </div>
        <div className={classes.cell} onClick={handleToggleExpand}>
          {name}
        </div>
        <div
          className={cn(
            classes.cell,
            classes.statusCell,
            syntheticStatus && classes[`status-${syntheticStatus}`]
          )}
          onClick={handleToggleExpand}
        >
          {t(parsStatus(syntheticStatus))}
          {isChip && (
            <div>
              <Chip
                label={labelChip}
                size="small"
                sx={{
                  "&.MuiChip-root": {
                    backgroundColor:
                      daysDiff > 1
                        ? "var(--accent-orange)"
                        : "var(--accent-red)",
                    color: "var(--white)",
                    borderRadius: "4px",
                    fontWeight: daysDiff > 1 ? "400" : "inherit",
                  },
                }}
              />
            </div>
          )}
        </div>
        {isExpanded && (
          <div
            className={cn(classes.expandedRow, isExpanded && classes.expanded)}
          >
            <div className={classes.messageCell}>{message}</div>
            {address && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                className={classes.addressCell}
              >
                <div>
                  <p className={classes.secondaryTextCell}>{t("ADDRESS")}</p>
                  <p
                    className={classes.address}
                    onClick={() => handleOpenAddressMobile(address)}
                  >
                    {address}
                  </p>
                  <MapAddressShow address={address} />
                </div>
                {isMobile && (
                  <IconButton
                    onClick={() => handleOpenAddressMobile(address)}
                    sx={{
                      color: "var(--success-green)",
                      boxShadow: "var(--button-round-minishadow)",
                      "&:hover": {
                        color: "var(--button-hover)",
                      },
                      "&:active": {
                        color: "var(--button-hover)",
                      },
                      "&:focus": {
                        color: "var(--button-hover)",
                      },
                    }}
                  >
                    <DirectionsIcon fontSize="large" />
                  </IconButton>
                )}
              </Stack>
            )}
            <div className={classes.buttons}>
              <Button
                variant="contained"
                type="button"
                onClick={() => setIsOpenModalDelete(true)}
                sx={{
                  backgroundColor: "var(--button-del)",
                  "&:hover": {
                    backgroundColor: "var(--button-hover)",
                  },
                  "&:active": {
                    backgroundColor: "var(--button-hover)",
                  },
                  "&:focus": {
                    backgroundColor: "var(--button-hover)",
                  },
                }}
              >
                {t("DELETE")}
              </Button>

              <Button
                variant="outlined"
                startIcon={<StopCircleIcon />}
                type="button"
                onClick={() => setIsOpenModalStop(true)}
                disabled={isDisabledStop}
                sx={{
                  color: "var(--button-stop)",
                  borderColor: "var(--button-stop)",
                  "&:hover": {
                    color: "var(--white)",
                    backgroundColor: "var(--button-stop)",
                    borderColor: "var(--button-stop)",
                  },
                  "&:focus": {
                    color: "var(--white)",
                    backgroundColor: "var(--button-stop)",
                    borderColor: "var(--button-stop)",
                  },
                }}
              >
                {t("STOP")}
              </Button>

              <Button
                variant="contained"
                startIcon={<GroupAddIcon />}
                type="button"
                onClick={() => setIsOpenModalLink(true)}
                disabled={isDisabledShare}
                sx={{
                  ml: "auto",
                  backgroundColor: "var(--button-primary)",
                  "&:hover": {
                    backgroundColor: "var(--button-hover)",
                  },
                  "&:active": {
                    backgroundColor: "var(--button-hover)",
                  },
                  "&:focus": {
                    backgroundColor: "var(--button-hover)",
                  },
                }}
              >
                {t("SHARE")}
              </Button>
              <IconButton
                onClick={() => getParticipants(eventlink)}
                disabled={isDisabledInfo}
                sx={{
                  padding: "0",
                  color: "var(--button-primary)",
                  "&:hover": {
                    color: "var(--button-hover)",
                  },
                  "&:active": {
                    color: "var(--button-hover)",
                  },
                  "&:focus": {
                    color: "var(--button-hover)",
                  },
                }}
              >
                <InfoIcon fontSize="large" />
              </IconButton>
            </div>
            <div
              className={cn(classes.createdDateCell, classes.secondaryTextCell)}
            >
              {t("CREATED_DATE")} {dateDDMMYYYY(createDate)}
            </div>
          </div>
        )}
      </div>
      {isOpenModalDelete && (
        <Modal
          id={_id}
          onSubmit={handleDel}
          open={isOpenModalDelete}
          closeModal={closeModalDelete}
          title={t("DELETE_EVENT")}
          text={t("WANT_DELETE_EVENT")}
        />
      )}

      {isOpenModalStop && (
        <Modal
          id={_id}
          onSubmit={handleStop}
          open={isOpenModalStop}
          closeModal={closeModalStop}
          title={t("STOP_EVENT")}
          text={t("WANT_STOP_EVENT")}
        />
      )}

      {isOpenModalLink && (
        <ModalLink
          id={_id}
          title={t("INV_EVENT")}
          keylink={linkInvitation}
          name={name}
          open={isOpenModalLink}
          closeModal={closeModalLink}
          isHotButton
          generateHotKey={generateHotKey}
          hotCode={hotCode}
          timeHotCode={timeHotCode}
        />
      )}
    </>
  );
};

export default TableRow;
