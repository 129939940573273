import React, { useState, useEffect, useContext } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import { ContextStore } from "context/ContextStore";

import { useHttp } from "hooks/useHttp.hook";

import { useTranslate } from "hooks/useTranslate.hook";

import { CircularProgress, Typography, Stack, Button } from "@mui/material";

export default function ConfirmPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [isRes, setIsRes] = useState(false);
  const [error, setError] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { request } = useHttp();
  const { t } = useTranslate();

  const { notif } = useContext(ContextStore);

  const confirmKey = searchParams.get("confirmKey") ?? "";

  useEffect(() => {
    confirm();
    // eslint-disable-next-line
  }, []);

  const confirm = async () => {
    try {
      const res = await request(
        `/api/auth/confirm?confirmKey=${confirmKey}`,
        "GET",
        null
      );
      setIsLoading(false);
      setIsRes(true);
      notif(res.message, "alert");
    } catch (e) {
      setIsLoading(false);
      setError(e);
      notif(e.message, "error");
    }
  };

  const handleContinue = () => {
    navigate("/");
  };

  return (
    <Stack justifyContent="center" alignItems="center" height="100%">
      {isLoading && (
        <CircularProgress
          sx={{
            color: "var( --accent-orange)",
          }}
        />
      )}
      {isRes && (
        <Typography
          variant="h4"
          component="p"
          color="var(--header-color)"
          align="center"
        >
          {t("CONG_EMAIL_CONFIRMED")}
        </Typography>
      )}
      {error && (
        <>
          <Typography
            mt="auto"
            mb="auto"
            variant="h4"
            component="p"
            color="var(--header-color)"
            align="center"
          >
            {t("CONFIRM_LINK_ERROR")}
          </Typography>
          <Button
            variant="contained"
            type="button"
            size="large"
            sx={{
              flex: "0 0 auto",
              backgroundColor: "var(--button-primary)",
              "&:hover": {
                backgroundColor: "var(--button-hover)",
              },
              "&:active": {
                backgroundColor: "var(--button-hover)",
              },
              "&:focus": {
                backgroundColor: "var(--button-hover)",
              },
            }}
            onClick={handleContinue}
          >
            {t("CONTINUE")}
          </Button>
        </>
      )}
    </Stack>
  );
}
