import React, { useState, useContext } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Link,
} from "@mui/material";
import WhatshotIcon from "@mui/icons-material/Whatshot";

import { QRCodeSVG } from "qrcode.react";

import { ContextStore } from "context/ContextStore";

import { useTranslate } from "hooks/useTranslate.hook";

import { Countdown } from "components/Elements";

import logo from "images/tim.svg";

const ModalLink = ({
  id,
  keylink,
  name,
  open,
  closeModal,
  title = "",
  isHotButton = false,
  generateHotKey,
  hotCode,
  timeHotCode,
}) => {
  const [keyCountdown, setKeyCountdown] = useState(0);
  const { t } = useTranslate();

  const { notif } = useContext(ContextStore);

  const handleCancel = () => {
    closeModal();
  };

  const keylinkWithHost = `${window.location.host}${keylink}`;

  const onHandleClick = () => {
    setKeyCountdown((key) => key + 1);
    generateHotKey(id);
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{`${title}`}</DialogTitle>
      <DialogContent align="center">
        <Typography
          variant="subtitle1"
          color="var(--header-color)"
          mb="20px"
          align="left"
        >
          {name}
        </Typography>

        <Box sx={{ height: "260px", mb: "20px" }}>
          {hotCode ? (
            <>
              <CopyToClipboard
                text={hotCode}
                onCopy={() => notif("HOT_KEY_COPY", "alert")}
              >
                <Typography variant="h3" color="var(--header-color)" mb="20px">
                  {hotCode}
                </Typography>
              </CopyToClipboard>
              <Countdown duration={timeHotCode} keyCountdown={keyCountdown} />
            </>
          ) : (
            <>
              <CopyToClipboard
                text={keylinkWithHost}
                onCopy={() => notif("LINK_COPY", "alert")}
              >
                <QRCodeSVG
                  value={keylinkWithHost}
                  size={256}
                  level={"L"}
                  includeMargin={false}
                  imageSettings={{
                    src: logo,
                    height: 50,
                    width: 50,
                    excavate: true,
                  }}
                />
              </CopyToClipboard>
            </>
          )}
        </Box>
        <CopyToClipboard
          text={keylinkWithHost}
          onCopy={() => notif("LINK_COPY", "alert")}
        >
          <Typography
            variant="body2"
            color="var(--header-color)"
            mb="10px"
            sx={{ opacity: "60%" }}
          >
            {t("CLICK_COPY_LINK")}
          </Typography>
        </CopyToClipboard>
        <CopyToClipboard
          text={keylinkWithHost}
          onCopy={() => notif("LINK_COPY", "alert")}
        >
          <Link sx={{ cursor: "pointer" }}>{keylink}</Link>
        </CopyToClipboard>
        {isHotButton && (
          <Box>
            <Button
              variant="contained"
              startIcon={<WhatshotIcon />}
              type="button"
              onClick={onHandleClick}
              sx={{
                mt: "20px",
                backgroundColor: "var(--button-primary)",
                "&:hover": {
                  backgroundColor: "var(--button-hover)",
                },
                "&:active": {
                  backgroundColor: "var(--button-hover)",
                },
                "&:focus": {
                  backgroundColor: "var(--button-hover)",
                },
              }}
            >
              {t("CREATE_HOT_CODE")}
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          sx={{
            color: "var(--button-modal)",
          }}
        >
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalLink;
