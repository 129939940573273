import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import { ContextStore } from "context/ContextStore";
import { useHttp } from "hooks/useHttp.hook";

const KeyUsePage = () => {
  const [eventDate, setEventDate] = useState(new Date());
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const [info, setInfo] = useState();
  const [error, setError] = useState(false);

  const { key } = useParams();

  const { request } = useHttp();
  const { token, notif } = useContext(ContextStore);

  useEffect(() => {
    validateKey();
    // eslint-disable-next-line
  }, []);

  const validateKey = async () => {
    try {
      const res = await request(`/api/keys/validate`, "POST", { key });
      setInfo(res);
    } catch (e) {
      setError(true);
      notif(e.message, "error");
    }
  };

  const onSubmit = async (data) => {
    const formData = { ...data, usedKey: key };
    try {
      await request(`/api/events/useKey`, "POST", formData, {
        Authorization: `Bearer ${token}`,
      });
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!eventDate || !name) {
      setValidationErrors({
        eventDate: !eventDate,
        name: !name,
      });
      return;
    }

    setValidationErrors({});

    onSubmit({ eventDate, name, message });

    setEventDate(new Date());
    setName("");
    setMessage("");
  };

  const handleDateChange = (date) => {
    setEventDate(date);
  };

  if (error) {
    return <p> Key is not defined</p>;
  }

  return (
    <div>
      <h4>Create Event for {info?.userFullName}</h4>

      <p>Name key: {info?.keyInfo.name}</p>
      <p>Message: {info?.keyInfo.message}</p>

      <div
        className={`input-field ${validationErrors.eventDate ? "error" : ""}`}
      >
        <p>Event Date</p>
        <DatePicker
          placeholderText="Event Date"
          selected={eventDate}
          onChange={handleDateChange}
          showTimeInput
          dateFormat="dd/MM/yyyy HH:mm"
          timeIntervals={1}
          minDate={new Date()}
        />
        {validationErrors.eventDate && (
          <span className="errorText">Event Date is required</span>
        )}
      </div>

      <div className={`input-field ${validationErrors.name ? "error" : ""}`}>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label htmlFor="name">Name</label>
        {validationErrors.name && (
          <span className="errorText">Name is required</span>
        )}
      </div>

      <div className="input-field">
        <input
          type="text"
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <label htmlFor="message">Message</label>
      </div>

      <button className="waves-green btn-flat" onClick={handleSubmit}>
        Create
      </button>
    </div>
  );
};

export default KeyUsePage;
