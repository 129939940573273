import { FormControlLabel, Switch } from "@mui/material";

const Toggle = ({ label, addYourself, handleSwitch }) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={addYourself}
          onChange={handleSwitch}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: "var( --button-primary)",
              "&:hover": {
                backgroundColor: "var(--button-primary-opacity)",
              },
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: "var( --button-primary)",
            },
          }}
        />
      }
      label={label}
      sx={{ marginBottom: "12px" }}
    />
  );
};

export default Toggle;
