import { useTranslate } from "hooks";

import { Typography, Stack } from "@mui/material";

import { dateDDMMYYYY, dateHHmm } from "utils/common";

const BodyModalInviteUseKey = ({ inviteInfo }) => {
  const { t } = useTranslate();

  return (
    <Stack pt="10px">
      <Typography variant="body2" component="p" sx={{ opacity: "60%" }}>
        {t("ORGANIZER")}
      </Typography>
      <Typography variant="body1" component="p" mb={2}>
        {inviteInfo?.ownerFullName}
      </Typography>

      <Typography variant="body2" component="p" sx={{ opacity: "60%" }}>
        {t("EVENT_DATE")}
      </Typography>
      <Typography variant="body1" component="p" mb={2}>
        {inviteInfo?.eventDate &&
          `${dateDDMMYYYY(inviteInfo?.eventDate)} ${dateHHmm(
            inviteInfo?.eventDate
          )}`}
      </Typography>

      <Typography variant="body2" component="p" sx={{ opacity: "60%" }}>
        {t("NAME")}
      </Typography>
      <Typography variant="body1" component="p" mb={2}>
        {inviteInfo?.name}
      </Typography>

      <Typography variant="body2" component="p" sx={{ opacity: "60%" }}>
        {t("MESSAGE")}
      </Typography>
      <Typography variant="body1" component="p">
        {inviteInfo?.message}
      </Typography>
    </Stack>
  );
};

export default BodyModalInviteUseKey;
