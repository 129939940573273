import { useState } from "react";

import { TextField, Box, Fab, Button } from "@mui/material";
import AddLocationIcon from "@mui/icons-material/AddLocation";

import { MapAddressSearch, CustomDateTimePicker } from "components/Elements";

import { useTranslate } from "hooks/useTranslate.hook";

const BodyModalForm = ({
  eventDate,
  setEventDate,
  message,
  setMessage,
  name,
  setName,
  validationErrors,
  address,
  setAddress,
}) => {
  const [isMap, setIsMap] = useState(false);

  const { t } = useTranslate();

  const handleCloseMap = () => {
    setIsMap(false);
    setAddress("");
  };

  return (
    <>
      <CustomDateTimePicker
        eventDate={eventDate}
        setEventDate={setEventDate}
        validationErrors={validationErrors.eventDate}
      />
      <TextField
        fullWidth
        id="standard-basic"
        label={t("NAME")}
        variant="standard"
        type="text"
        name="name"
        onChange={(e) => setName(e.target.value)}
        value={name}
        error={validationErrors.name}
        helperText={validationErrors.name && t("ERROR_NAME_REQ")}
        sx={{
          mt: "16px",
          "& label.Mui-focused": {
            color: "var(--label-focused)",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "var(--underline-focused)",
          },
        }}
      />
      <TextField
        fullWidth
        id="standard-basic"
        label={t("MESSAGE")}
        variant="standard"
        type="text"
        name="message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        sx={{
          mt: "16px",
          "& label.Mui-focused": {
            color: "var(--label-focused)",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "var(--underline-focused)",
          },
        }}
      />
      <Box mt="16px">
        {isMap ? (
          <MapAddressSearch
            handleClose={handleCloseMap}
            address={address}
            setAddress={setAddress}
          />
        ) : (
          <Button
            endIcon={
              <Fab
                component="div"
                size="small"
                sx={{
                  backgroundColor: "white",
                  color: "var(--accent-red)",
                }}
              >
                <AddLocationIcon />
              </Fab>
            }
            type="button"
            onClick={() => setIsMap(true)}
            sx={{
              textTransform: "none",
              color: "var(--black)",
              paddingLeft: "0",
            }}
          >
            {t("ADD_ADDRESS")}
          </Button>
        )}
      </Box>
    </>
  );
};

export default BodyModalForm;
