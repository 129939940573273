import { MapContainer, TileLayer } from "react-leaflet";

import { CircularProgress, Stack } from "@mui/material";

import { useTranslate, useMapAddress } from "hooks";

import { startGeolocation } from "config/configs";

const MapAddressShow = ({ address }) => {
  const { t } = useTranslate();

  const { error, markerPosition, SimpleMarker, loading } =
    useMapAddress(address);

  return loading ? (
    <Stack
      justifyContent="center"
      alignItems="center"
      width="300px"
      height="200px"
    >
      <CircularProgress
        sx={{
          color: "var( --accent-orange)",
        }}
      />
    </Stack>
  ) : (
    <>
      {markerPosition && (
        <MapContainer
          center={markerPosition || startGeolocation}
          zoom={13}
          style={{ height: "200px", width: "300px" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <SimpleMarker />
        </MapContainer>
      )}
      {error && (
        <p style={{ fontSize: "12px", color: "red" }}>
          {t("ADDRESS_NOT_FOUND")}
        </p>
      )}
    </>
  );
};

export default MapAddressShow;
