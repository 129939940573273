import { useState, useContext } from "react";

import { ContextStore } from "context/ContextStore";
import { useHttp } from "hooks";

export const useGetParticipants = () => {
  const [participants, setParticipants] = useState([]);
  const [isOpenModalParticipants, setIsOpenModalParticipants] = useState(false);

  const { request } = useHttp();
  const { token, notif } = useContext(ContextStore);

  const closeModalParticipants = () => {
    setIsOpenModalParticipants(false);
    setParticipants([]);
  };

  const getParticipants = async (eventlink) => {
    try {
      const res = await request(
        `/api/events/getEventParticipants/${eventlink}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setParticipants(res);
      setIsOpenModalParticipants(true);
    } catch (e) {
      notif(e.message, "error");
    }
  };

  return {
    participants,
    isOpenModalParticipants,
    getParticipants,
    closeModalParticipants,
  };
};
