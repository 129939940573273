import { Tabs, Tab, Box } from "@mui/material";

import {
  ModalKey,
  KeyCard,
  Modal,
  BodyModalAddKey,
  SpeedDialMenu,
  ModalQrReader,
} from "components/Elements";

import { useTranslate, useKeyPage, useGetInfoKey } from "hooks";

import classes from "./KeysPage.module.scss";

const KeysPage = () => {
  const { t } = useTranslate();

  const {
    filteredKeys,
    filter,
    userId,
    handleSubmit,
    fetchDelKey,
    fetchSaveEditKey,
    fetchAddEventForKey,
    handleTabChange,
    getDataTable,
    handleAddMenu,
    isOpenModalCreate,
    isOpenModalQrReader,
    handleQrButton,
    handleAddButton,
    closeModalCreate,
    closeModalQrReader,
  } = useKeyPage();

  const {
    link,
    isOpenModalAdd,
    keyInfo,
    joinKey,
    closeModalAdd,
    removeParamUseLink,
  } = useGetInfoKey(getDataTable);

  return (
    <>
      <div className={classes.wrap} onClick={handleAddMenu}>
        <Box
          sx={{
            "&.MuiBox-root": {
              backgroundColor: "var(--grey-middle)",
              mb: "10px",
              ml: "-12px",
              mr: "-12px",
            },
          }}
        >
          <Tabs
            value={filter}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              p: "10px",
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            <Tab
              value="all"
              label={t("ALL_KEYS")}
              sx={{
                borderRadius: "4px",
                "&.Mui-selected": {
                  backgroundColor: "var(--white)",
                  color: "inherit",
                },
              }}
            />
            <Tab
              value="my"
              label={t("MY_KEY")}
              sx={{
                borderRadius: "4px",
                "&.Mui-selected": {
                  backgroundColor: "var(--white)",
                  color: "inherit",
                },
              }}
            />
            <Tab
              value="friends"
              label={t("FRIENDS_KEYS")}
              sx={{
                borderRadius: "4px",
                "&.Mui-selected": {
                  backgroundColor: "var(--white)",
                  color: "inherit",
                },
              }}
            />
          </Tabs>
        </Box>
        {filteredKeys.map((item) => (
          <KeyCard
            data={item}
            key={item._id}
            fetchSaveEditKey={fetchSaveEditKey}
            fetchDelKey={fetchDelKey}
            fetchAddEventForKey={fetchAddEventForKey}
            userId={userId}
          />
        ))}

        <SpeedDialMenu
          handleQrButton={handleQrButton}
          handleAddButton={handleAddButton}
          isHot={false}
        />
        {isOpenModalCreate && (
          <ModalKey
            onSubmit={handleSubmit}
            open={isOpenModalCreate}
            closeModal={closeModalCreate}
            title={t("CREATE_KEY")}
            textButtonOk={t("CREATE")}
          />
        )}
        {isOpenModalQrReader && (
          <ModalQrReader
            open={isOpenModalQrReader}
            closeModal={closeModalQrReader}
          />
        )}

        {isOpenModalAdd && (
          <Modal
            id={link}
            onSubmit={joinKey}
            open={isOpenModalAdd}
            closeModal={closeModalAdd}
            title={t("ADD_KEY_TITLE")}
            text={<BodyModalAddKey keyInfo={keyInfo} />}
            textButtonOk={t("ADD_KEY")}
            additionalFuncCancel={removeParamUseLink}
          />
        )}
      </div>
    </>
  );
};

export default KeysPage;
