import React from "react";
import * as MuiIcons from "@mui/icons-material";

export default function IconMuiDraw({ name, sx = {} }) {
  let IconComponent = MuiIcons[name];

  if (!IconComponent) {
    IconComponent = MuiIcons["CropDin"];
  }

  return <IconComponent sx={sx} />;
}
