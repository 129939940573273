import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@mui/material";

import MessageIcon from "@mui/icons-material/Message";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { useTranslate } from "hooks/useTranslate.hook";

import { dateDDMMYYYY, dateHHmm } from "utils/common";

export default function ModalList({
  clearAllAlerts,
  open,
  closeModal,
  handleDelAlert,
  listAlerts,
}) {
  const { t } = useTranslate();

  const handleClose = () => {
    closeModal();
  };

  const handleClear = () => {
    clearAllAlerts();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}> {t("NOTIFICATIONS")}</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <List>
          {listAlerts.map(({ id, message, read, date }) => (
            <ListItem
              key={id}
              secondaryAction={
                <IconButton edge="end" onClick={() => handleDelAlert(id)}>
                  <DeleteIcon />
                </IconButton>
              }
              sx={{
                backgroundColor: !read && "var( --grey-middle)",
                borderRadius: "6px",
                marginBottom: "2px",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: "var( --black)",
                  }}
                >
                  <MessageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={message}
                secondary={`${dateDDMMYYYY(date)} ${dateHHmm(date)}`}
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontSize: "12px",
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClear}
          sx={{ marginRight: "auto", color: "var(--button-modal)" }}
        >
          {t("CLEAR_ALL")}
        </Button>
        <Button
          autoFocus
          onClick={handleClose}
          sx={{
            color: "var(--button-modal)",
          }}
        >
          {t("CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
