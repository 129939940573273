import { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

import { BodyModalForm } from "components/Elements";

import { useTranslate } from "hooks/useTranslate.hook";

const ModalForm = ({ onSubmit, open, closeModal, title }) => {
  const [eventDate, setEventDate] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [address, setAddress] = useState("");

  const { t } = useTranslate();

  const handleClose = () => {
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!eventDate || !name) {
      setValidationErrors({
        eventDate: !eventDate,
        name: !name,
      });
      return;
    }

    setValidationErrors({});

    onSubmit({ eventDate, name, message, address });

    handleClose();

    setEventDate("");
    setName("");
    setMessage("");
    setAddress("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <BodyModalForm
          eventDate={eventDate}
          setEventDate={setEventDate}
          message={message}
          setMessage={setMessage}
          name={name}
          setName={setName}
          validationErrors={validationErrors}
          address={address}
          setAddress={setAddress}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            color: "var(--button-modal)",
          }}
        >
          {t("CANCEL")}
        </Button>
        <Button
          type="submit"
          sx={{
            color: "var(--button-modal)",
          }}
        >
          {t("CREATE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalForm;
