import {
  Button,
  Box,
  Stack,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import { AvatarProfile, SaveButton, ModalSupport } from "components/Elements";

import { useTranslate, useMenuPage } from "hooks";

const MenuPage = () => {
  const { t } = useTranslate();

  const {
    isLoading,
    info,
    isEdit,
    isOpenModalSupport,
    openModalSupport,
    closeModalSupport,
    updateProfileInfo,
    confirm,
    handleField,
    chooseImgHandler,
    removeImgHandler,
    handleLogout,
    handleSupportReport,
  } = useMenuPage();

  return (
    <>
      <Stack direction="column" spacing={2} height="100%">
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <CircularProgress
              sx={{
                color: "var( --accent-orange)",
              }}
            />
          </Stack>
        ) : (
          <Stack spacing={2} flexGrow={1} component="form" onSubmit={() => {}}>
            <Stack alignItems="center">
              <AvatarProfile
                fullName={info.fullName}
                foto={info.avatar}
                addAvatar={chooseImgHandler}
                removeAvatar={removeImgHandler}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <TextField
                fullWidth
                id="standard-basic"
                label={t("EMAIL")}
                variant="standard"
                type="email"
                name="email"
                onChange={handleField}
                value={info?.email}
                helperText={info?.emailIsConfirm && t("EMAIL_CONFIRMED")}
                sx={{
                  ".MuiFormHelperText-root": {
                    color: "var(--success-green)",
                  },
                  "& label.Mui-focused": {
                    color: "var(--label-focused)",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "var(--underline-focused)",
                  },
                }}
              />
              <Button
                variant="contained"
                type="button"
                size="large"
                sx={{
                  flex: "0 0 auto",
                  backgroundColor: "var(--button-primary)",
                  "&:hover": {
                    backgroundColor: "var(--button-hover)",
                  },
                  "&:active": {
                    backgroundColor: "var(--button-hover)",
                  },
                  "&:focus": {
                    backgroundColor: "var(--button-hover)",
                  },
                }}
                onClick={confirm}
              >
                {t("CONFIRM_MAIL")}
              </Button>
            </Stack>

            <TextField
              fullWidth
              id="standard-basic"
              label={t("FULL_NAME")}
              variant="standard"
              type="text"
              name="fullName"
              onChange={handleField}
              value={info?.fullName}
              sx={{
                "& label.Mui-focused": {
                  color: "var(--label-focused)",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "var(--underline-focused)",
                },
              }}
            />
          </Stack>
        )}
        <Box align="center">
          <Stack
            mb={5}
            spacing={1}
            sx={{
              alignItems: "center",
              width: 280,
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
              }}
            >
              <HelpOutlineIcon />
              <Typography variant="subtitle2">{t("SUPPORT")}</Typography>
            </Stack>
            <Typography color="var(--grey)" variant="body2">
              {t("USE_FORM_SUPPORT")}
            </Typography>

            <Button
              variant="outlined"
              size="small"
              startIcon={<MailOutlineIcon />}
              sx={{
                textTransform: "none",
                color: "var(--button-support)",
                borderColor: "var(--button-support)",
                "&:hover": {
                  borderColor: "var(--button-support)",
                },
                "&:focus": {
                  borderColor: "var(--button-support)",
                },
              }}
              onClick={openModalSupport}
            >
              {t("EMAIL_SUPPORT")}
            </Button>
          </Stack>

          <Button
            variant="contained"
            type="button"
            size="large"
            sx={{
              backgroundColor: "var(--button-primary)",
              "&:hover": {
                backgroundColor: "var(--button-hover)",
              },
              "&:active": {
                backgroundColor: "var(--button-hover)",
              },
              "&:focus": {
                backgroundColor: "var(--button-hover)",
              },
            }}
            onClick={handleLogout}
          >
            {t("LOGOUT")}
          </Button>
        </Box>
        {isEdit && <SaveButton onClick={updateProfileInfo} />}
      </Stack>
      {isOpenModalSupport && (
        <ModalSupport
          onSubmit={handleSupportReport}
          open={isOpenModalSupport}
          closeModal={closeModalSupport}
          fullNameInfo={info?.fullName}
          emailInfo={info?.email}
        />
      )}
    </>
  );
};

export default MenuPage;
