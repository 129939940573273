import { useState, useEffect, useContext } from "react";

import { ContextStore } from "context/ContextStore";

import { useHttp } from "hooks";

export const useModalUseKey = (onSubmit, closeModal, usedKey) => {
  const [eventDate, setEventDate] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [eventlink, setEventlink] = useState("");
  const [addYourself, setAddYourself] = useState(false);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [info, setInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");

  const [valueTab, setValueTab] = useState("add");
  const [infoTab, setInfoTab] = useState("add");
  const [inviteInfo, setInviteInfo] = useState();

  const [isOpenModalQrReader, setIsOpenModalQrReader] = useState(false);
  const [isOpenModalHot, setIsOpenModalHot] = useState(false);

  const { request } = useHttp();
  const { token, notif } = useContext(ContextStore);

  const handleClose = () => {
    closeModal();
  };

  useEffect(() => {
    validateKey();
    // eslint-disable-next-line
  }, []);

  const validateKey = async () => {
    setIsLoading(true);
    try {
      const res = await request(`/api/keys/validate`, "POST", {
        key: usedKey,
      });
      setInfo(res);
    } catch (e) {
      notif(e.message, "error");
      closeModal();
    } finally {
      setIsLoading(false);
    }
  };

  const getInfoQrReaderKey = (data) => {
    const url = new URL(`http://${data}`);
    const searchParams = new URLSearchParams(url.search);

    if (searchParams.has("useKey")) {
      const link = searchParams.get("useKey");
      setEventlink(link);
      getInfo(link);
    } else {
      notif("INCORRECT_QR", "error");
      startTabs();
    }
  };

  const getInfo = async (link) => {
    try {
      const res = await request(`/api/events/getInfo/${link}`, "GET", null, {
        Authorization: `Bearer ${token}`,
      });
      setInviteInfo(res);
      setInfoTab("qr");
    } catch (e) {
      notif(e.message, "error");
      startTabs();
    }
  };

  const useHotKeyToKeys = async (hotKey) => {
    const REQ = { hotKey, addYourself, usedKey };
    try {
      const message = await request(`/api/events/useHotKey`, "POST", REQ, {
        Authorization: `Bearer ${token}`,
      });
      // getDataTable();
      notif(message, "alert");
      closeModal();
    } catch (e) {
      startTabs();
      notif(e.message, "error");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (infoTab === "add") {
      if (!eventDate || !name) {
        setValidationErrors({
          eventDate: !eventDate,
          name: !name,
        });
        return;
      }

      setValidationErrors({});
      onSubmit({ eventDate, name, message, usedKey, addYourself, address });
    }

    if (infoTab === "qr") {
      onSubmit({ usedKey, addYourself, eventlink });
    }

    // Закрытие модального окна
    handleClose();

    // Очистка полей ввода
    setEventDate("");
    setName("");
    setMessage("");
    setInfo("");
    setAddYourself();
    setEventlink();
    setInviteInfo();
    setAddress("");
  };

  const handleSwitchAddYourself = (e) => {
    setAddYourself(e.target.checked);
  };

  const handleSwitchOrganizer = (e) => {
    setIsOrganizer(e.target.checked);
  };

  const handleTab = (event, newValue) => {
    setValueTab(newValue);
    setInviteInfo();
  };

  const handleClickAdd = () => {
    setInfoTab("add");
  };

  const handleClickQr = () => {
    setIsOpenModalQrReader(true);
  };

  const handleClickHot = () => {
    setIsOpenModalHot(true);
  };

  const closeModalQrReader = () => {
    setIsOpenModalQrReader(false);
  };

  const closeModalHot = () => {
    setIsOpenModalHot(false);
  };

  const startTabs = () => {
    setValueTab("add");
    setInfoTab("add");
  };

  return {
    eventDate,
    setEventDate,
    name,
    setName,
    message,
    setMessage,
    addYourself,
    validationErrors,
    info,
    isLoading,
    valueTab,
    infoTab,
    inviteInfo,
    isOpenModalQrReader,
    isOpenModalHot,
    handleSwitchAddYourself,
    getInfoQrReaderKey,
    handleClose,
    handleSubmit,
    handleTab,
    handleClickAdd,
    handleClickQr,
    handleClickHot,
    closeModalQrReader,
    closeModalHot,
    startTabs,
    useHotKeyToKeys,
    isOrganizer,
    handleSwitchOrganizer,
    address,
    setAddress,
  };
};
