import { Avatar } from "@mui/material";

import { fullNamePars } from "utils/common";

const AvatarImg = ({ fullName, foto, size, fontSize }) => {
  const name = fullNamePars(fullName);

  return (
    <Avatar
      alt={name}
      src={foto}
      sx={{
        bgcolor: "var(--avatar-bg)",
        color: "var(--avatar-color)",
        width: size,
        height: size,
        fontSize: fontSize,
        fontWeight: 900,
      }}
    >
      {name}
    </Avatar>
  );
};

export default AvatarImg;
