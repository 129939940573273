import React, { useState } from "react";

import {
  Box,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  Avatar,
  Button,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { useTranslate } from "hooks/useTranslate.hook";

import { langConfig } from "config/configs";

function SelectLanguage({ changeLanguage, lng }) {
  const [anchorLang, setAnchorLang] = useState(null);

  const { t } = useTranslate();

  const handleOpenLangMenu = (event) => {
    setAnchorLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorLang(null);
  };

  const changeLang = (value) => {
    changeLanguage(value);
    handleCloseLangMenu();
  };

  const iconTextLangPars = (value) => {
    const currentItem = langConfig.find((item) => item.value === value);
    return { icon: currentItem?.icon, text: currentItem?.text };
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title={t("CHANGE_LANGUAGE")}>
        <Button
          onClick={handleOpenLangMenu}
          variant="outlined"
          sx={{
            p: "2px",
            borderColor: "var(--grey-darkmiddle)",
            color: "var(--white)",
            "&:hover": {
              borderColor: "var(--grey-darkmiddle)",
              backgroundColor: "var(----white)",
            },
            "&:focus": {
              borderColor: "var(--grey-darkmiddle)",
              backgroundColor: "var(----white)",
            },
            "&:active": {
              borderColor: "var(--grey-darkmiddle)",
              backgroundColor: "var(----white)",
            },
          }}
        >
          <Avatar
            alt={iconTextLangPars(lng).text}
            src={iconTextLangPars(lng).icon}
            sx={{ width: "26px", height: "26px" }}
          />
          {Boolean(anchorLang) ? (
            <ArrowDropUpIcon sx={{ color: "var(--grey-darkmiddle)" }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: "var(--grey-darkmiddle)" }} />
          )}
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: "35px" }}
        id="menu-appbar"
        anchorEl={anchorLang}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorLang)}
        onClose={handleCloseLangMenu}
      >
        {langConfig.map(({ icon, text, value }) => (
          <MenuItem key={value} onClick={() => changeLang(value)}>
            <Avatar
              alt={text}
              src={icon}
              sx={{ width: "18px", height: "18px", mr: "5px" }}
            />
            <Typography textAlign="center">{text}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default SelectLanguage;
