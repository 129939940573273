import { useTranslate } from "hooks";

import { dateDDMMYYYY, dateHHmm } from "utils/common";

const BodyModalInvite = ({ inviteInfo }) => {
  const { eventDate, name, ownerFullName } = inviteInfo;

  const { t } = useTranslate();

  return (
    <>
      <p>{t("ACCEPT_INVITATION")}</p>
      <p>{name}</p>
      <p>{`${dateDDMMYYYY(eventDate)} ${dateHHmm(eventDate)}`}</p>
      <p>{ownerFullName}</p>
    </>
  );
};

export default BodyModalInvite;
