import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const PrivateRoute = ({ isAllowed, redirectPath = "/login" }) => {
  const location = useLocation();

  const createRedirecLink = () => {
    return `${redirectPath}${location.search}`;
  };

  if (!isAllowed) {
    return (
      <Navigate to={createRedirecLink()} replace state={{ from: location }} />
    );
  }

  return <Outlet />;
};

export default PrivateRoute;
