import { useTranslate } from "hooks";

const BodyModalAddKey = ({ keyInfo }) => {
  const { name, ownerFullName } = keyInfo;

  const { t } = useTranslate();
  return (
    <>
      <p>{t("WANT_ADD_KEY")}</p>
      <p>{name}</p>
      <p>{ownerFullName}</p>
    </>
  );
};

export default BodyModalAddKey;
