import io from "socket.io-client";

const SOCKET_PATH = "https://managerstage.onrender.com";
let socket;

export const initSocket = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.close();
  }

  socket = io(SOCKET_PATH, {
    autoConnect: true,
    path: "/socket.io",
    forceNew: true,
    withCredentials: false,
    transportOptions: {
      polling: {
        extraHeaders: {
          "X-USER-TOKEN": localStorage.getItem("token"),
        },
      },
    },
  });
};

export const getSocket = () => {
  if (!socket) {
    return false;
  }
  return socket;
};
