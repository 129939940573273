import { useState, useEffect, useContext } from "react";

import { useSearchParams } from "react-router-dom";

import { ContextStore } from "context/ContextStore";
import { useHttp } from "hooks";
import { removeQueryParam } from "utils/common";

export const useGetInfoInvite = (getDataTable) => {
  const [isOpenModalInvite, setIsOpenModalInvite] = useState(false);
  const [inviteInfo, setInviteInfo] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  const { request } = useHttp();
  const { token, notif } = useContext(ContextStore);

  const link = searchParams.get("useKey") ?? "";

  useEffect(() => {
    if (searchParams.has("useKey")) {
      getInfo(link);
    }
    // eslint-disable-next-line
  }, [searchParams]);

  const closeModalInvite = () => {
    setIsOpenModalInvite(false);
  };

  const joinEvent = async (eventlink) => {
    const REQ = { eventlink: eventlink };
    try {
      await request(`/api/events/joinEvent`, "POST", REQ, {
        Authorization: `Bearer ${token}`,
      });
      removeParamUseKey();
      getDataTable();
    } catch (e) {
      removeParamUseKey();
      notif(e.message, "error");
    }
  };

  const getInfo = async (link) => {
    try {
      const res = await request(`/api/events/getInfo/${link}`, "GET", null, {
        Authorization: `Bearer ${token}`,
      });
      setInviteInfo(res);
      setIsOpenModalInvite(true);
    } catch (e) {
      removeParamUseKey();
      notif(e.message, "error");
    }
  };

  const removeParamUseKey = () => {
    removeQueryParam("useKey", searchParams, setSearchParams);
  };

  return {
    link,
    isOpenModalInvite,
    inviteInfo,
    closeModalInvite,
    joinEvent,
    removeParamUseKey,
    getInfo,
  };
};
