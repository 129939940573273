import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import { dateHHmm } from "utils/common";

import "react-big-calendar/lib/css/react-big-calendar.css";

import classes from "./EventsCalendar.module.scss";
import "./EventsCalendar.css";

const EventsCalendar = ({ events, onClickCalendarEvent }) => {
  const localizer = momentLocalizer(moment);

  const calendarEvents = [...events].map((item) => ({
    id: item._id,
    title: item.name,
    start: new Date(item.eventDate),
    end: new Date(item.eventDate),
  }));

  const customEventDay = ({ event }) => {
    return (
      <div>
        <span className={classes.eventLabel}>{dateHHmm(event.start)}</span>
        {event.title}
      </div>
    );
  };

  const handleSelectEvent = (event) => {
    onClickCalendarEvent(event.id);
  };

  return (
    <Calendar
      localizer={localizer}
      events={calendarEvents}
      startAccessor="start"
      endAccessor="end"
      views={["month", "day"]}
      tooltipAccessor={null}
      onSelectEvent={handleSelectEvent}
      components={{
        day: {
          event: customEventDay,
        },
      }}
    />
  );
};

export default EventsCalendar;
