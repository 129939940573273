import moment from "moment";

export const parsStatus = (statusId) => {
  switch (statusId) {
    case 1:
      return "CANCELED";
    case 2:
      return "PENDING";
    case 3:
      return "SUCCESS";
    case 4:
      return "EXPIRED";
    case 5:
      return "DELETED";
    default:
      return "UNKNOWN";
  }
};

export const dateDDMMYYYY = (date) => moment(date).format("DD/MM/YYYY");
export const dateHHmm = (date) => moment(date).format("HH:mm");
export const dateMoment = (date) => moment(date);

export const diffInDays = (date) => {
  const currentDate = moment().startOf("day");
  const futureDate = moment(date).startOf("day");

  return futureDate.diff(currentDate, "days");
};

export const diffInMinutes = (date) => {
  const currentDate = moment().startOf("minute");
  const futureDate = moment(date).startOf("minute");

  return futureDate.diff(currentDate, "minutes");
};

export const removeQueryParam = (paramKey, searchParams, setSearchParams) => {
  if (searchParams.has(paramKey)) {
    searchParams.delete(paramKey);
  }
  setSearchParams(searchParams);
};

export const fullNamePars = (fullName) => {
  const nameParts = fullName.split(" ");
  const firstLetter = nameParts[0]?.charAt(0).toUpperCase() ?? "";
  const secondLetter = nameParts[1]?.charAt(0).toUpperCase() ?? "";

  return `${firstLetter}${secondLetter}`;
};

export const passwordRegex = /^[A-Za-z0-9!@#$%^&*()_+=\-{}[\]:;<>,.?/]{8,16}$/;
