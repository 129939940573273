import { useState, useEffect, useContext } from "react";

import { useSearchParams } from "react-router-dom";

import { ContextStore } from "context/ContextStore";
import { useHttp } from "hooks";
import { removeQueryParam } from "utils/common";

export const useGetInfoKey = (getDataTable) => {
  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);
  const [keyInfo, setKeyInfo] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  const { request } = useHttp();
  const { token, notif } = useContext(ContextStore);

  const link = searchParams.get("useLink") ?? "";

  useEffect(() => {
    if (searchParams.has("useLink")) {
      getKey();
    }
    // eslint-disable-next-line
  }, [searchParams]);

  const closeModalAdd = () => {
    setIsOpenModalAdd(false);
  };

  const joinKey = async (keylink) => {
    const REQ = { keylink: keylink };
    try {
      await request(`/api/keys/joinKey`, "POST", REQ, {
        Authorization: `Bearer ${token}`,
      });
      removeParamUseLink();
      getDataTable();
    } catch (e) {
      removeParamUseLink();
      notif(e.message, "error");
    }
  };

  const removeParamUseLink = () => {
    removeQueryParam("useLink", searchParams, setSearchParams);
  };

  const getKey = async () => {
    try {
      const res = await request(`/api/keys/getKeyInfo/${link}`, "GET", null, {
        Authorization: `Bearer ${token}`,
      });
      setKeyInfo(res);
      setIsOpenModalAdd(true);
    } catch (e) {
      removeParamUseLink();
      notif(e.message, "error");
    }
  };

  return {
    link,
    isOpenModalAdd,
    keyInfo,
    joinKey,
    closeModalAdd,
    removeParamUseLink,
  };
};
