import { isMobile } from "react-device-detect";
import moment from "moment";

import { InputAdornment } from "@mui/material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import {
  LocalizationProvider,
  DatePicker,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { useTranslate } from "hooks/useTranslate.hook";

const CustomDatePicker = ({
  expiredDate,
  setExpiredDate,
  validationErrors,
}) => {
  const { t } = useTranslate();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isMobile && (
        <MobileDatePicker
          label={t("EXPIRED_DATE")}
          value={moment(expiredDate)}
          onChange={(date) => setExpiredDate(date)}
          onError={(error) => validationErrors}
          minDate={moment(new Date())}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              variant: "standard",
              focused: true,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <EditCalendarIcon />
                  </InputAdornment>
                ),
              },
              error: validationErrors,
              helperText: validationErrors && t("ERROR_EXPIRED_DATE_REQ"),
            },
            day: {
              sx: {
                "&.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: "var(--accent-orange)",
                },
              },
            },
            mobilePaper: {
              sx: {
                "& .MuiPickersYear-yearButton.Mui-selected": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiPickersYear-yearButton.Mui-selected:hover": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiPickersYear-yearButton.Mui-selected:focus": {
                  backgroundColor: "var(--accent-orange)",
                },
              },
            },
            actionBar: {
              sx: {
                "& button": {
                  color: "var(--button-modal)",
                },
              },
            },
          }}
          sx={{
            mt: "10px",
            "& label.Mui-focused": {
              color: "var(--label-focused)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--underline-focused)",
            },
          }}
        />
      )}
      {!isMobile && (
        <DatePicker
          label={t("EXPIRED_DATE")}
          value={moment(expiredDate)}
          onChange={(date) => setExpiredDate(date)}
          onError={(error) => validationErrors}
          minDate={moment(new Date())}
          format="DD/MM/YYYY"
          slots={{
            openPickerIcon: EditCalendarIcon,
          }}
          slotProps={{
            // openPickerButton: { color: "primary" },
            textField: {
              variant: "standard",
              focused: true,
              error: validationErrors,
              helperText: validationErrors && t("ERROR_EXPIRED_DATE_REQ"),
            },
            day: {
              sx: {
                "&.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: "var(--accent-orange)",
                },
              },
            },
            desktopPaper: {
              sx: {
                "& .MuiPickersYear-yearButton.Mui-selected": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiPickersYear-yearButton.Mui-selected:hover": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiPickersYear-yearButton.Mui-selected:focus": {
                  backgroundColor: "var(--accent-orange)",
                },
              },
            },
            actionBar: {
              sx: {
                "& button": {
                  color: "var(--button-modal)",
                },
              },
            },
          }}
          sx={{
            mt: "10px",
            "& label.Mui-focused": {
              color: "var(--label-focused)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--underline-focused)",
            },
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
