import { IconButton } from "@mui/material";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloseIcon from "@mui/icons-material/Close";

import { AvatarImg } from "components/Elements";

import classes from "./AvatarProfile.module.scss";

const AvatarProfile = ({ fullName, foto, addAvatar, removeAvatar }) => {
  return (
    <div className={classes.avatarWrap}>
      <AvatarImg fullName={fullName} foto={foto} size={150} fontSize={50} />
      <div className={classes.btnAvatar}>
        {foto ? (
          <IconButton
            onClick={removeAvatar}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <>
            <label htmlFor="changeImg">
              <AddAPhotoIcon
                fontSize="large"
                sx={{
                  padding: "0",
                  color: "var(--button-primary)",
                  "&:hover": {
                    color: "var(--button-hover)",
                  },
                  "&:active": {
                    color: "var(--button-hover)",
                  },
                  "&:focus": {
                    color: "var(--button-hover)",
                  },
                }}
              />
            </label>
            <input
              id="changeImg"
              onChange={addAvatar}
              accept="image/*"
              type="file"
              alt="your image"
              className={classes.hidden}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AvatarProfile;
