import { Tabs, Tab, Fab } from "@mui/material";

import { IconMuiDraw } from "components/Elements";

const TabsPanel = ({
  handleClickAdd,
  handleClickQr,
  handleClickHot,
  valueTab,
  handleTab,
  isDisabled,
}) => {
  const dataTabsPanel = [
    {
      onClickTab: handleClickAdd,
      value: "add",
      icon: "Add",
      disabled: false,
    },
    {
      onClickTab: handleClickQr,
      value: "qr",
      icon: "QrCode2",
      disabled: isDisabled,
    },
    {
      onClickTab: handleClickHot,
      value: "hot",
      icon: "Whatshot",
      disabled: isDisabled,
    },
  ];

  return (
    <Tabs
      value={valueTab}
      onChange={handleTab}
      variant="fullWidth"
      sx={{
        marginBottom: "18px",
        "& .MuiTabs-indicator": {
          backgroundColor: "var(--button-hover)",
        },
      }}
    >
      {dataTabsPanel.map(({ onClickTab, value, icon, disabled }) => (
        <Tab
          key={value}
          onClick={onClickTab}
          value={value}
          disabled={disabled}
          icon={
            <Fab
              component="div"
              size="small"
              sx={{
                color: "white",
                "&": {
                  backgroundColor: "var(--button-primary)",
                },
                "&:hover": {
                  backgroundColor: "var(--orange-secondary)",
                },
                ".Mui-selected &": {
                  backgroundColor: "var(--button-hover)",
                  transitionDelay: "0.2s",
                },
                ".Mui-disabled &": {
                  backgroundColor: "var(--grey-notactive)",
                  boxShadow: "none",
                },
              }}
            >
              <IconMuiDraw name={icon} />
            </Fab>
          }
        />
      ))}
    </Tabs>
  );
};

export default TabsPanel;
