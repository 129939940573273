import { isMobile } from "react-device-detect";
import moment from "moment";

import { InputAdornment } from "@mui/material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import {
  LocalizationProvider,
  DateTimePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { useTranslate } from "hooks/useTranslate.hook";

const CustomDateTimePicker = ({
  eventDate,
  setEventDate,
  validationErrors,
}) => {
  const { t } = useTranslate();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isMobile && (
        <MobileDateTimePicker
          label={t("EVENT_DATE")}
          value={moment(eventDate)}
          onChange={(date) => setEventDate(date)}
          onError={(error) => validationErrors}
          minDateTime={moment(new Date())}
          format="DD/MM/YYYY HH:mm"
          ampm={false}
          slotProps={{
            textField: {
              variant: "standard",
              focused: true,
              error: validationErrors,
              helperText: validationErrors && t("ERROR_EVENT_DATE_REQ"),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <EditCalendarIcon />
                  </InputAdornment>
                ),
              },
            },
            day: {
              sx: {
                "&.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: "var(--accent-orange)",
                },
              },
            },
            mobilePaper: {
              sx: {
                "& .MuiPickersYear-yearButton.Mui-selected": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiPickersYear-yearButton.Mui-selected:hover": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiPickersYear-yearButton.Mui-selected:focus": {
                  backgroundColor: "var(--accent-orange)",
                },
              },
            },
            layout: {
              sx: {
                "& .MuiTab-root.Mui-selected": {
                  color: "var(--accent-orange)",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiClockPointer-thumb": {
                  backgroundColor: "var(--accent-orange)",
                  borderColor: "var(--accent-orange)",
                },
                "& .MuiClock-pin": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiClockNumber-root.Mui-selected": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiClockPointer-root": {
                  backgroundColor: "var(--accent-orange)",
                },
              },
            },
            actionBar: {
              sx: {
                "& button": {
                  color: "var(--button-modal)",
                },
              },
            },
          }}
          sx={{
            mt: "10px",
            "& label.Mui-focused": {
              color: "var(--label-focused)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--underline-focused)",
            },
          }}
        />
      )}
      {!isMobile && (
        <DateTimePicker
          label={t("EVENT_DATE")}
          value={moment(eventDate)}
          onChange={(date) => setEventDate(date)}
          onError={(error) => validationErrors}
          minDateTime={moment(new Date())}
          format="DD/MM/YYYY HH:mm"
          ampm={false}
          slots={{
            openPickerIcon: EditCalendarIcon,
          }}
          slotProps={{
            // openPickerButton: { color: "primary" },
            textField: {
              error: validationErrors,
              helperText: validationErrors && t("ERROR_EVENT_DATE_REQ"),
              variant: "standard",
              focused: true,
            },
            day: {
              sx: {
                "&.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: "var(--accent-orange)",
                },
              },
            },
            desktopPaper: {
              sx: {
                "& .MuiPickersYear-yearButton.Mui-selected": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiPickersYear-yearButton.Mui-selected:hover": {
                  backgroundColor: "var(--accent-orange)",
                },
                "& .MuiPickersYear-yearButton.Mui-selected:focus": {
                  backgroundColor: "var(--accent-orange)",
                },
                ".MuiMultiSectionDigitalClockSection-item.Mui-selected": {
                  backgroundColor: "var(--accent-orange)",
                },
                ".MuiMultiSectionDigitalClockSection-item.Mui-selected:hover": {
                  backgroundColor: "var(--accent-orange)",
                },
              },
            },
            actionBar: {
              sx: {
                "& button": {
                  color: "var(--button-modal)",
                },
              },
            },
          }}
          sx={{
            mt: "10px",
            "& label.Mui-focused": {
              color: "var(--label-focused)",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "var(--underline-focused)",
            },
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;
