import { useState } from "react";

import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { QrReader } from "react-qr-reader";

import { useTranslate } from "hooks";

import { Modal } from "components/Elements";

export default function ModalQrReader({ open, closeModal }) {
  const [data, setData] = useState(null);
  const [isOpenModalLink, setIsOpenModalLink] = useState(false);

  const { t } = useTranslate();

  const handleClose = () => {
    closeModal();
  };

  const closeModalLink = () => {
    setIsOpenModalLink(false);
  };

  const openLink = (link) => {
    window.location = `http://${link}`;
    // window.open(`http://${link}`, "_blank");
  };

  // const isValidUrl = (text) => {
  //   try {
  //     new URL(text);
  //     return true;
  //   } catch (error) {
  //     return false;
  //   }
  // };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          flexGrow: "1",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>{t("QR_SCANNING")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ p: 2 }}>
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (!!result) {
              setData(result?.text);
              if (result?.text) {
                // if (isValidUrl(result.text)) {
                // } else {
                // }
                setIsOpenModalLink(true);
              }
            }

            if (!!error) {
              // console.info(error);
            }
          }}
          style={{ width: "100%" }}
        />
        {isOpenModalLink && (
          <Modal
            id={data}
            onSubmit={openLink}
            open={isOpenModalLink}
            closeModal={closeModalLink}
            title={t("FOLLOW_LINK")}
            text={data}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
