import { useState, useEffect, useContext } from "react";

import { ContextStore } from "context/ContextStore";
import { useHttp } from "hooks";

import { diffInDays, diffInMinutes, dateMoment } from "utils/common";

export const useMessagesPage = () => {
  const [events, setEvents] = useState([]);
  const [isOpenModalQrReader, setIsOpenModalQrReader] = useState(false);
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [isOpenModalHot, setIsOpenModalHot] = useState(false);
  const [checked, setChecked] = useState(() => {
    return JSON.parse(localStorage.getItem("onlyPending")) ?? false;
  });
  const [viewMode, setViewMode] = useState(() => {
    return JSON.parse(localStorage.getItem("viewMode")) ?? "table";
  });
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [hotCode, setHotCode] = useState(null);
  const [timerId, setTimerId] = useState(null);
  const [isOpenModalCalendarEvent, setIsOpenModalCalendarEvent] =
    useState(false);
  const [currentCalendarEventId, setCurrentCalendarEventId] = useState(null);

  const { request } = useHttp();
  const { token, userId, notif, pageScrollDown } = useContext(ContextStore);

  useEffect(() => {
    getDataTable();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    eventFilter(checked, events);
    // eslint-disable-next-line
  }, [events, checked]);

  useEffect(() => {
    localStorage.setItem("onlyPending", JSON.stringify(checked));
  }, [checked]);

  useEffect(() => {
    localStorage.setItem("viewMode", JSON.stringify(viewMode));
  }, [viewMode]);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const closeModalCreate = () => {
    setIsOpenModalCreate(false);
  };

  const handleAddButton = () => {
    setIsOpenModalCreate(true);
  };

  const closeModalQrReader = () => {
    setIsOpenModalQrReader(false);
  };

  const handleQrButton = () => {
    setIsOpenModalQrReader(true);
  };

  const closeModalHot = () => {
    setIsOpenModalHot(false);
  };

  const handleHotButton = () => {
    setIsOpenModalHot(true);
  };

  const openModalCalendarEvent = () => {
    setIsOpenModalCalendarEvent(true);
  };

  const closeModalCalendarEvent = () => {
    setIsOpenModalCalendarEvent(false);
  };

  const onClickCalendarEvent = (id) => {
    setCurrentCalendarEventId(id);
    if (id) {
      openModalCalendarEvent();
    }
  };

  const handleSubmit = async (formData) => {
    try {
      await request(`/api/events/addEvent`, "POST", formData, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const handleDel = async (_id) => {
    const REQ = { id: _id };
    try {
      await request(`/api/events/delEvent`, "POST", REQ, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const handleStop = async (_id) => {
    const REQ = { eventId: _id, newStatus: 1 };
    try {
      await request(`/api/events/updateStatus`, "POST", REQ, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const getDataTable = async () => {
    try {
      const eventsRes = await request(`/api/events/getAll`, "GET", null, {
        Authorization: `Bearer ${token}`,
      });
      setEvents(eventsRes);
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const handleSwitch = (e) => {
    setChecked(e.target.checked);
  };

  const handleModeView = (e, newValue) => {
    setViewMode(newValue);
  };

  const eventFilter = (valueChecked, arr) => {
    const sortedArr = [...arr].sort((a, b) =>
      dateMoment(b.eventDate).diff(dateMoment(a.eventDate))
    );

    if (valueChecked) {
      setFilteredEvents(
        sortedArr.filter(
          (item) => syntheticStatusParse(item.eventDate, item.status) === 2
        )
      );
    } else {
      setFilteredEvents(sortedArr);
    }
  };

  const syntheticStatusParse = (eventDate, status) => {
    const daysDiff = diffInDays(eventDate);

    return daysDiff < 0 || (daysDiff === 0 && diffInMinutes(eventDate) <= 2)
      ? 3
      : status;
  };

  const generateHotKey = async (_id) => {
    try {
      const { hotKey, message } = await request(
        `/api/events/generateHotKey/${_id}`,
        "PUT",
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setHotCode(hotKey);
      startTimer();
      notif(message, "alert");
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const timeHotCode = 60;

  const startTimer = () => {
    timerId && clearTimeout(timerId);
    const timer = setTimeout(function () {
      setHotCode(null);
    }, timeHotCode * 1000);
    setTimerId(timer);
  };

  const useHotKey = async (key) => {
    const REQ = { hotKey: key };
    try {
      const message = await request(`/api/events/useHotKey`, "POST", REQ, {
        Authorization: `Bearer ${token}`,
      });
      getDataTable();
      notif(message, "alert");
    } catch (e) {
      notif(e.message, "error");
    }
  };

  return {
    filteredEvents,
    isOpenModalCreate,
    isOpenModalQrReader,
    isOpenModalHot,
    userId,
    closeModalCreate,
    handleAddButton,
    closeModalQrReader,
    handleQrButton,
    closeModalHot,
    handleHotButton,
    handleSubmit,
    handleDel,
    handleStop,
    getDataTable,
    checked,
    handleSwitch,
    hotCode,
    setHotCode,
    generateHotKey,
    timeHotCode,
    timerId,
    useHotKey,
    pageScrollDown,
    handleModeView,
    viewMode,
    isOpenModalCalendarEvent,
    closeModalCalendarEvent,
    onClickCalendarEvent,
    currentCalendarEventId,
  };
};
