import { useState, useCallback, useEffect } from "react";
import { DEFAULT_LANGUAGE } from "config/configs";

import { useNotif } from "./index";

const storageName = "userData";

export const useStore = (wrapPageRef) => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [ready, setReady] = useState(false);
  const [lng, setLng] = useState(DEFAULT_LANGUAGE);
  const [trigerSocket, setTrigerSocket] = useState(null);
  const [isOpenModalTutorial, setIsOpenModalTutorial] = useState(false);

  const { notif } = useNotif();

  const isAuthenticated = !!token;

  const login = useCallback((jwtToken, id) => {
    setToken(jwtToken);
    setUserId(id);
    localStorage.setItem(
      storageName,
      JSON.stringify({ userId: id, token: jwtToken })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    localStorage.removeItem(storageName);
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName));
    if (data && data.token) {
      login(data.token, data.userId);
    }
    setReady(true);
  }, [login]);

  useEffect(() => {
    const startLng = localStorage.getItem("currentLanguage");
    if (startLng) {
      setLng(startLng);
    }
  }, []);

  const changeLanguage = (value) => {
    setLng(value);
    localStorage.setItem("currentLanguage", value);
  };

  const pageScrollDown = () => {
    if (wrapPageRef.current) {
      setTimeout(() => {
        wrapPageRef.current.scrollTo({
          top: wrapPageRef.current.scrollHeight,
          behavior: "smooth",
        });
      }, 300);
    }
  };

  return {
    token,
    userId,
    login,
    logout,
    ready,
    isAuthenticated,
    lng,
    changeLanguage,
    notif,
    pageScrollDown,
    trigerSocket,
    setTrigerSocket,
    isOpenModalTutorial,
    setIsOpenModalTutorial,
  };
};
