import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";

import {
  ModalQrReaderKey,
  ModalUseHot,
  BodyModalInviteUseKey,
  TabsPanel,
  BodyModalForm,
  Toggle,
} from "components/Elements";

import { useTranslate, useModalUseKey } from "hooks";

const ModalUseKey = ({ onSubmit, open, closeModal, title, usedKey }) => {
  const { t } = useTranslate();

  const {
    eventDate,
    setEventDate,
    name,
    setName,
    message,
    setMessage,
    addYourself,
    validationErrors,
    info,
    isLoading,
    valueTab,
    infoTab,
    inviteInfo,
    isOpenModalQrReader,
    isOpenModalHot,
    handleSwitchAddYourself,
    getInfoQrReaderKey,
    handleClose,
    handleSubmit,
    handleTab,
    handleClickAdd,
    handleClickQr,
    handleClickHot,
    closeModalQrReader,
    closeModalHot,
    startTabs,
    useHotKeyToKeys,
    isOrganizer,
    handleSwitchOrganizer,
    address,
    setAddress,
  } = useModalUseKey(onSubmit, closeModal, usedKey);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
        }}
      >
        {isLoading ? (
          <DialogContent>
            <Stack
              justifyContent="center"
              alignItems="center"
              width="240px"
              height="350px"
              spacing={2}
            >
              <CircularProgress
                sx={{
                  color: "var( --accent-orange)",
                }}
              />
              <Typography variant="body1" color="var( --accent-orange)">
                {t("KEY_LOADING")}
              </Typography>
            </Stack>
          </DialogContent>
        ) : (
          <>
            <DialogTitle>
              {title} {info?.userFullName}
            </DialogTitle>
            <DialogContent>
              <Toggle
                label={t("ADD_EVENT_YOURSELF")}
                addYourself={addYourself}
                handleSwitch={handleSwitchAddYourself}
              />
              <br />
              {addYourself && (
                <Toggle
                  label={t("EVENT_ORGANIZE")}
                  addYourself={isOrganizer}
                  handleSwitch={handleSwitchOrganizer}
                />
              )}

              <Typography variant="body1" component="p" mb={2}>
                <Typography
                  variant="body1"
                  component="span"
                  color="var(--accent-orange)"
                >
                  {t("NAME_KEY")}:{" "}
                </Typography>
                {info?.keyInfo.name}
              </Typography>
              <Typography variant="body1" component="p" mb={2}>
                <Typography
                  variant="body1"
                  component="span"
                  color="var(--accent-orange)"
                >
                  {t("MESSAGE")}:{" "}
                </Typography>
                {info?.keyInfo.message}
              </Typography>
              <TabsPanel
                handleClickAdd={handleClickAdd}
                handleClickQr={handleClickQr}
                handleClickHot={handleClickHot}
                valueTab={valueTab}
                handleTab={handleTab}
                isDisabled={isOrganizer}
              />
              {infoTab === "add" && (
                <BodyModalForm
                  eventDate={eventDate}
                  setEventDate={setEventDate}
                  message={message}
                  setMessage={setMessage}
                  name={name}
                  setName={setName}
                  validationErrors={validationErrors}
                  address={address}
                  setAddress={setAddress}
                />
              )}
              {infoTab === "qr" && (
                <BodyModalInviteUseKey inviteInfo={inviteInfo} />
              )}
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              color: "var(--button-modal)",
            }}
          >
            {t("CANCEL")}
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            sx={{
              color: "var(--button-modal)",
            }}
          >
            {t("CREATE")}
          </Button>
        </DialogActions>
      </Dialog>

      {isOpenModalQrReader && (
        <ModalQrReaderKey
          open={isOpenModalQrReader}
          closeModal={closeModalQrReader}
          getInfoQrReaderKey={getInfoQrReaderKey}
          additionalFunc={startTabs}
        />
      )}

      {isOpenModalHot && (
        <ModalUseHot
          onSubmit={useHotKeyToKeys}
          open={isOpenModalHot}
          closeModal={closeModalHot}
          additionalFunc={startTabs}
          title={t("INSERT_HOT_KEY")}
          textButtonOk={t("USE_HOT_KEY")}
          isUseKey={true}
          addYourself={addYourself}
          handleSwitch={handleSwitchAddYourself}
        />
      )}
    </>
  );
};

export default ModalUseKey;
