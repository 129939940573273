import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useTranslate } from "hooks/useTranslate.hook";

export default function Modal({
  id,
  onSubmit,
  open,
  closeModal,
  title,
  text,
  textButtonOk,
  textButtonCancel,
  additionalFuncCancel,
}) {
  const { t } = useTranslate();

  const handleCancel = () => {
    if (additionalFuncCancel) {
      additionalFuncCancel();
    }
    closeModal();
  };

  const handleOk = () => {
    onSubmit(id);
    closeModal();
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{`${title}`}</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
          sx={{
            color: "var(--button-modal)",
          }}
        >
          {textButtonCancel || t("CANCEL")}
        </Button>
        <Button
          onClick={handleOk}
          autoFocus
          sx={{
            color: "var(--button-modal)",
          }}
        >
          {textButtonOk || t("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
