import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useTranslate } from "hooks/useTranslate.hook";

export default function ModalAlert({
  open,
  closeModal,
  title,
  text,
  textButtonOk,
}) {
  const { t } = useTranslate();

  const handleOk = () => {
    closeModal();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleOk}>
        <DialogTitle>{`${title}`}</DialogTitle>
        {text && (
          <DialogContent>
            <DialogContentText component="div">{text}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleOk}
            autoFocus
            sx={{
              color: "var(--button-modal)",
            }}
          >
            {textButtonOk || t("OK")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
