import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function ModalCalendarEvent({ open, closeModal, body }) {
  const handleCancel = () => {
    closeModal();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullScreen
      style={{
        bottom: "56px",
        top: "auto",
      }}
      PaperProps={{
        sx: {
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: "absolute",
          right: 4,
          top: 4,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers style={{ marginTop: "44px", padding: "0" }}>
        {body}
      </DialogContent>
    </Dialog>
  );
}
