import { Tabs, Tab } from "@mui/material";

import { IconMuiDraw } from "components/Elements";

import classes from "./TabsModeView.module.scss";

const TabsModeView = ({ viewModeConfig, valueTab, handleTab }) => {
  return (
    <Tabs
      value={valueTab}
      onChange={handleTab}
      TabIndicatorProps={{
        sx: {
          display: "none",
        },
      }}
      sx={{
        overflow: "visible",
        "& .MuiTabs-scroller": {
          overflow: "visible !important",
        },
        ".MuiTabs-flexContainer": {
          gap: "10px",
        },
      }}
    >
      {viewModeConfig.map(({ value, icon }) => (
        <Tab
          key={value}
          value={value}
          icon={
            <IconMuiDraw
              name={icon}
              sx={{
                fontSize: valueTab === value ? "33px" : "35px",
              }}
              className={classes.effect}
            />
          }
          sx={{
            padding: "8px",
            minWidth: "51px",
            borderRadius: "50%",
            color: "var(--grey-darkmiddle)",
            boxShadow:
              valueTab === value ? "none" : "var(--button-round-minishadow)",
            "&.Mui-selected": {
              color: "var(--button-primary)",
            },
          }}
          className={classes.effect}
        ></Tab>
      ))}
    </Tabs>
  );
};

export default TabsModeView;
