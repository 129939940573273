import React from "react";

import Fab from "@mui/material/Fab";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import classes from "./SaveButton.module.scss";

const SaveButton = ({ onClick = () => {} }) => {
  return (
    <div className={classes.button} onClick={onClick}>
      <Fab
        sx={{
          width: "100px",
          height: "100px",
          backgroundColor: "var(--button-accent)",
          color: "white",
          "&:hover": {
            backgroundColor: "var(--button-hover)",
            color: "white",
          },
          "&:active": {
            backgroundColor: "var(--button-hover)",
            color: "white",
            boxShadow: "var(--button-round-shadow)",
          },
          "&:focus": {
            backgroundColor: "var(--button-hover)",
            color: "white",
          },
        }}
      >
        <SaveAsIcon sx={{ fontSize: "70px" }} />
      </Fab>
    </div>
  );
};

export default SaveButton;
