import flagEng from "images/flagEng.svg";
import flagUa from "images/flagUa.svg";
import flagRu from "images/flagRu.svg";

export const navConfig = [
  { link: "/events", text: "EVENTS", icon: "SupervisorAccount", counter: 0 },
  { link: "/keys", text: "KEYS", icon: "VpnKey", counter: 0 },
  { link: "/menu", text: "MENU", icon: "Settings", counter: 0 },
];

export const redirectTypes = [
  {
    nameLink: "useLink",
    title: "ERROR_USELINK",
  },
  {
    nameLink: "useKey",
    title: "ERROR_USEKEY",
  },
];

export const DEFAULT_LANGUAGE = "eng";

export const langConfig = [
  {
    icon: flagEng,
    text: "ENG",
    value: "eng",
  },
  {
    icon: flagUa,
    text: "UA",
    value: "ua",
  },
  {
    icon: flagRu,
    text: "RU",
    value: "rus",
  },
];

export const viewModeConfig = [
  {
    value: "table",
    icon: "TableChart",
  },
  {
    value: "calendar",
    icon: "CalendarMonth",
  },
];

export const startGeolocation = [50.4501, 30.5234];
