import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { Modal, CustomDatePicker } from "components/Elements";

import { useTranslate } from "hooks/useTranslate.hook";

const ModalKey = ({
  onSubmit,
  open,
  closeModal,
  nameUser,
  expiredDateUser,
  usageCountUser,
  messageUser,
  title,
  textButtonOk,
  textButtonCancel,
  isDeleteButton = false,
  onDelete,
  id,
  isFriendsKey = false,
}) => {
  const [expiredDate, setExpiredDate] = useState(
    expiredDateUser || new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
  );
  const [name, setName] = useState(nameUser || "");
  const [usageCount, setUsageCount] = useState(usageCountUser || 1);
  const [message, setMessage] = useState(messageUser || "");
  const [validationErrors, setValidationErrors] = useState({});

  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  const { t } = useTranslate();

  const handleOpenDelete = () => {
    setIsOpenModalDelete(true);
  };
  const handleCloseDelete = () => {
    setIsOpenModalDelete(false);
  };

  const handleClose = () => {
    closeModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!expiredDate || !name || (usageCount !== "unlimited" && !usageCount)) {
      setValidationErrors({
        expiredDate: !expiredDate,
        name: !name,
        usageCount: usageCount !== "unlimited" && !usageCount ? true : false,
      });
      return;
    }

    setValidationErrors({});

    onSubmit({ expiredDate, name, usageCount, message }, id);

    handleClose();

    setExpiredDate(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000));
    setName("");
    setUsageCount(1);
    setMessage("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      {!isFriendsKey && (
        <DialogContent>
          <CustomDatePicker
            expiredDate={expiredDate}
            setExpiredDate={setExpiredDate}
            validationErrors={validationErrors.expiredDate}
          />

          <TextField
            fullWidth
            id="standard-basic"
            label={t("NAME")}
            variant="standard"
            type="text"
            name="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            error={validationErrors.name}
            helperText={validationErrors.name && t("ERROR_NAME_REQ")}
            sx={{
              mt: "16px",
              "& label.Mui-focused": {
                color: "var(--label-focused)",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "var(--underline-focused)",
              },
            }}
          />

          <FormControl variant="standard" sx={{ mt: "16px", minWidth: 120 }}>
            <InputLabel
              sx={{ "&.Mui-focused": { color: "var(--label-focused)" } }}
            >
              {t("USAGE_COUNT")}
            </InputLabel>
            <Select
              labelId="standard-label"
              id="elect-standard"
              value={usageCount}
              onChange={(e) => setUsageCount(e.target.value)}
              sx={{
                "&.MuiInput-underline:after": {
                  borderBottomColor: "var(--underline-focused)",
                },
              }}
            >
              <MenuItem value="unlimited">{t("UNLIMITED")}</MenuItem>
              {[1, 2, 3, 4, 5].map((count) => (
                <MenuItem key={count} value={count}>
                  {count}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            id="standard-basic"
            label={t("MESSAGE")}
            variant="standard"
            type="text"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{
              mt: "16px",
              "& label.Mui-focused": {
                color: "var(--label-focused)",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "var(--underline-focused)",
              },
            }}
          />
        </DialogContent>
      )}
      <DialogActions>
        {isDeleteButton && (
          <Button
            variant="contained"
            type="button"
            size="small"
            // color=""
            sx={{
              mr: "auto",
              ml: "16px",
              backgroundColor: "var(--button-del)",
              "&:hover": {
                backgroundColor: "var(--button-hover)",
              },
              "&:active": {
                backgroundColor: "var(--button-hover)",
              },
              "&:focus": {
                backgroundColor: "var(--button-hover)",
              },
            }}
            onClick={handleOpenDelete}
          >
            {t("DELETE")}
          </Button>
        )}

        <Button
          sx={{
            color: "var(--button-modal)",
          }}
          onClick={handleClose}
        >
          {textButtonCancel || t("CANCEL")}
        </Button>
        {!isFriendsKey && (
          <Button
            sx={{
              color: "var(--button-modal)",
            }}
            type="submit"
          >
            {textButtonOk || t("OK")}
          </Button>
        )}
      </DialogActions>

      {isOpenModalDelete && (
        <Modal
          id={id}
          onSubmit={onDelete}
          open={isOpenModalDelete}
          closeModal={handleCloseDelete}
          title={t("DELETE_KEY")}
          text={t("WANT_DELETE_KEY")}
        />
      )}
    </Dialog>
  );
};

export default ModalKey;
