import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { QrReader } from "react-qr-reader";

import { useTranslate } from "hooks";

export default function ModalQrReaderKey({
  open,
  closeModal,
  getInfoQrReaderKey,
  additionalFunc,
}) {
  const { t } = useTranslate();

  const handleClose = () => {
    closeModal();
    additionalFunc();
  };

  // const isValidUrl = (text) => {
  //   try {
  //     new URL(text);
  //     return true;
  //   } catch (error) {
  //     return false;
  //   }
  // };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          flexGrow: "1",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>{t("QR_SCANNING")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ p: 2 }}>
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (!!result) {
              if (result?.text) {
                // if (isValidUrl(result.text)) {
                // } else {
                // }
                getInfoQrReaderKey(result?.text);
                closeModal();
              }
            }

            if (!!error) {
              // console.info(error);
            }
          }}
          style={{ width: "100%" }}
        />
      </DialogContent>
    </Dialog>
  );
}
