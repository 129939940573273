import React, { useState, useEffect, useContext } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import { Stack, Box, Tabs, Tab, TextField, Button } from "@mui/material";

import {
  ModalAlert,
  SelectLanguage,
  ModalField,
  Logo,
  PasswordField,
} from "components/Elements";

import { useHttp } from "hooks/useHttp.hook";
import { ContextStore } from "context/ContextStore";

import { useTranslate } from "hooks";

import { redirectTypes } from "config/configs";

import { passwordRegex } from "utils/common";

import classes from "./LoginPage.module.scss";

const AuthPage = () => {
  const { loading, request, error, clearError } = useHttp();
  const { login, lng, changeLanguage, notif, setIsOpenModalTutorial } =
    useContext(ContextStore);
  const [activeTab, setActiveTab] = useState("login");
  const [form, setForm] = useState({
    email: "",
    fullName: "",
    password: "",
    confirmPassword: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isOpenModalForgot, setIsOpenModalForgot] = useState(false);

  const { t } = useTranslate();

  useEffect(() => {
    if (location.search) {
      redirectTypes.forEach((item) => {
        if (searchParams.get(item.nameLink)) {
          setActiveTab("register");
          setModalTitle(item.title);
          setIsOpenModal(true);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
    clearError();
    // eslint-disable-next-line
  }, [error, clearError]);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const closeModalForgot = () => {
    setIsOpenModalForgot(false);
  };

  const changeHandler = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleTabChange = (event, newValue) => {
    // !!!!!!!! remove blocck after prod
    setActiveTab(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !form.email ||
      !form.password ||
      (activeTab === "register" && (!form.fullName || !form.confirmPassword))
    ) {
      notif("ERROR_FILL_FIELDS", "error");
      return;
    }

    if (activeTab === "register" && !passwordRegex.test(form.password)) {
      notif("ERROR_REGISTER_PASS", "error");
      return;
    }

    try {
      let data;

      if (activeTab === "login") {
        data = await request("/api/auth/login", "POST", { ...form });
      } else {
        if (form.password !== form.confirmPassword) {
          notif("ERROR_NOTMATCH_PASS", "error");
          return;
        }
        data = await request("/api/auth/register", "POST", { ...form });
      }
      login(data.token, data.userId);

      firstEntry();

      if (location.state) {
        if (location.state.from?.pathname !== "/login") {
          navigate(location.state.from, { replace: true });
        }
      }
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const sendResetPassLink = async (email) => {
    try {
      const { message } = await request(
        "/api/auth/createResetPassLink",
        "POST",
        {
          email,
        }
      );
      notif(message, "alert");
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const firstEntry = () => {
    const isFirstTme = JSON.parse(localStorage.getItem("isFirstTime"));
    if (isFirstTme === null) {
      setIsOpenModalTutorial(true);
      localStorage.setItem("isFirstTime", JSON.stringify(false));
    }
  };

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        backgroundColor="var(--background-light)"
        height="100%"
      >
        <Box backgroundColor="var(--white)" className={classes.authBox}>
          <div className={classes.selectLanguage}>
            <SelectLanguage lng={lng} changeLanguage={changeLanguage} />
          </div>

          <Box mb="20px">
            <Logo height="60" />
          </Box>

          <Box mb="10px">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "var(--accent-orange)",
                },
              }}
            >
              <Tab
                label={t("LOGIN")}
                value="login"
                sx={{
                  "&.Mui-selected": {
                    color: "var(--tab-color)",
                  },
                }}
              />
              <Tab
                label={t("REGISTER")}
                value="register"
                sx={{
                  "&.Mui-selected": {
                    color: "var(--tab-color)",
                  },
                }}
              />
            </Tabs>
          </Box>
          <Stack
            component="form"
            spacing={2}
            alignItems="center"
            onSubmit={handleSubmit}
          >
            <TextField
              fullWidth
              label={t("EMAIL")}
              variant="standard"
              type="email"
              name="email"
              onChange={changeHandler}
              value={form.email}
              sx={{
                "& label.Mui-focused": {
                  color: "var(--label-focused)",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "var( --underline-focused)",
                },
              }}
            />
            {activeTab === "register" && (
              <TextField
                fullWidth
                label={t("FULL_NAME")}
                variant="standard"
                type="text"
                name="fullName"
                onChange={changeHandler}
                value={form.fullName}
                sx={{
                  "& label.Mui-focused": {
                    color: "var(--label-focused)",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "var( --underline-focused)",
                  },
                }}
              />
            )}
            <PasswordField
              label={t("PASSWORD")}
              name="password"
              value={form.password}
              onChange={changeHandler}
            />
            {activeTab === "register" && (
              <PasswordField
                label={t("CONFIRM_PASSWORD")}
                name="confirmPassword"
                value={form.confirmPassword}
                onChange={changeHandler}
              />
            )}
            {activeTab === "login" && (
              <Button
                variant="text"
                sx={{ textTransform: "none", color: "var(--black)" }}
                onClick={() => setIsOpenModalForgot(true)}
              >
                {t("FORGOT_PASS")}
              </Button>
            )}
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              size="large"
              sx={{
                backgroundColor: "var(--button-primary)",
                "&:hover": {
                  backgroundColor: "var(--button-hover)",
                },
                "&:active": {
                  backgroundColor: "var(--button-hover)",
                },
                "&:focus": {
                  backgroundColor: "var(--button-hover)",
                },
              }}
            >
              {t(activeTab === "login" ? "LOGIN" : "REGISTER")}
            </Button>
          </Stack>
        </Box>
      </Stack>

      {isOpenModal && (
        <ModalAlert
          open={isOpenModal}
          closeModal={closeModal}
          title={t(modalTitle)}
        />
      )}

      {isOpenModalForgot && (
        <ModalField
          onSubmit={sendResetPassLink}
          open={isOpenModalForgot}
          closeModal={closeModalForgot}
          title={t("ENTER_MAIL")}
          textButtonOk={t("SEND_LINK")}
        />
      )}
    </>
  );
};

export default AuthPage;
