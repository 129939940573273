import { useState, useEffect, useCallback } from "react";

import { Marker, useMapEvents, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

import debounce from "lodash.debounce";

export const useMapAddress = (address, setAddress) => {
  const [markerPosition, setMarkerPosition] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const customMarkerIcon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon2x,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  useEffect(() => {
    if (address) {
      geocode(address);
    }
  }, [address]);

  const geocode = async (addressValue) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
          addressValue
        )}&format=json`
      );
      const data = await response.json();
      if (data && data.length > 0) {
        const { lat, lon } = data[0];
        setMarkerPosition([parseFloat(lat), parseFloat(lon)]);
        setError(false);
      } else {
        setError(true);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleSearchGeocode = async (addressValue) => {
    if (addressValue.trim() === "") {
      setError(false);
      return;
    }
    geocode(addressValue);
  };

  // eslint-disable-next-line
  const debouncedChangeAdress = useCallback(
    debounce((value) => {
      handleSearchGeocode(value);
    }, 1000),
    []
  );

  const changeAdress = (e) => {
    setAddress(e.target.value);
    debouncedChangeAdress(e.target.value);
  };

  const reverseGeocode = async (lat, lon) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
      );
      const data = await response.json();
      if (data && data.address) {
        const { road, house_number, city, country } = data.address;
        const fullAddress = `${road || ""} ${house_number || ""}, ${
          city || ""
        }, ${country || ""}`.trim();
        setAddress(fullAddress);
      }
    } catch (e) {}
  };

  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setMarkerPosition([lat, lng]);
        reverseGeocode(lat, lng);
      },
      // locationfound(e) {
      //   const { lat, lng } = e.latlng;
      //   setMarkerPosition([lat, lng]);
      // },
    });

    useEffect(() => {
      if (markerPosition) {
        map.flyTo(markerPosition, map.getZoom());
      }
      // eslint-disable-next-line
    }, [markerPosition, map]);

    return markerPosition === null ? null : (
      <Marker position={markerPosition} icon={customMarkerIcon} />
    );
  };

  const SimpleMarker = () => {
    return (
      <Marker position={markerPosition} icon={customMarkerIcon}>
        <Popup>{address}</Popup>
      </Marker>
    );
  };

  return {
    changeAdress,
    error,
    markerPosition,
    LocationMarker,
    SimpleMarker,
    loading,
  };
};
